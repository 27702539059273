import { MatPaginatorIntl } from '@angular/material/paginator';

const frRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) { return `0 sur ${length}`; }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
    startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} sur ${length}`;
};


export function getFrPaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = 'max par page'; // pas affiché
    paginatorIntl.firstPageLabel = 'Première page';
    paginatorIntl.lastPageLabel = 'Dernière page';
    paginatorIntl.nextPageLabel = 'Page suivante';
    paginatorIntl.previousPageLabel = 'Page précédente';
    paginatorIntl.getRangeLabel = frRangeLabel;

    return paginatorIntl;
}
