/*
 * QEOPS
 * Author EFWAY - F. Delaunay
 */

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { MetaEditionFeedback, ActionFeedback, ActionEnum } from '@src/app/_core/interfaces/app.interfaces';
import { ItemTypeEnum, BeDocumentFileRequest } from '@src/app/_core/interfaces/rest-api.interfaces';
import { FileUtilities as FU } from '@src/app/_core/utils/file-utilities';
import { ErrorService } from '@src/app/_core/services/error.service';
import { AlertService } from '@src/app/_core/services/alert.service';
import { DocumentService } from '@src/app/_shared/document/document.service';
import { Document } from '@src/app/_core/model/document.model';

/*
 * FORM to add a new doc ...
 *     either as attachment to an EXISTING Item -> manages upload autonomously
 *     either as a sub-field of a CREATION form -> delegates upload to the parent component
 * Author EFWAY - F. Delaunay
 * todo? extend with title & comment fields and use it by GuideDocument Edition
 */

@Component({
    selector: 'wid-docform',
    templateUrl: './doc-form.component.html',
})
export class DocumentFormComponent implements OnInit {
    @Input() parentId?: number = null;    // parent item to which document is attached. If null => item CREATION context
    @Input() parentType?: ItemTypeEnum;   // ... and its  type
    //todo: add optional asserts customizations

    // ONLY in CREATION CONTEXT(no itemId): emitted at each document change to let parent component manage upload:
    @Output() documentChanged = new EventEmitter<MetaEditionFeedback<BeDocumentFileRequest>>();

    // ONLY in UPDATE CONTEXT (itemId): emitted once doc is uploaded (and attached to the item) :
    @Output() documentUploaded = new EventEmitter<ActionFeedback<Document>>();

    //@ViewChild('documentInput', { static: true }) fileInput: ElementRef;
    unicizer: string; // DOM id suffix that ensures unicity because we can have several instances of this component in the same page

    myForm: FormGroup;

    submitting: boolean = false;
    uploadProgress$: Observable<number> = this.documentService.uploadProgress$;

    ASSERTS = {
        file: { maxweight: 20}, // MB
    };

    constructor(
        private elementRef : ElementRef,
        private formBuilder: FormBuilder,
        private errorService: ErrorService,
        private alertService: AlertService,
        private documentService: DocumentService
    ) { }

    ngOnInit(): void {
        this.unicizer = `${this.parentId}-${Math.floor(Math.random() * 10e16)}`;
        this.myForm = this.buildForm();
    }

    private buildForm(): FormGroup {
        return this.myForm = this.formBuilder.group({
            file: [],
        });
    }

    onFileChange(event): void {
        let reader = new FileReader();
        if(event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            let fileReq: BeDocumentFileRequest = null;
            reader.readAsDataURL(file); //reader.readAsBinaryString(file);
            reader.onload = () => {
                if ( file.size > this.ASSERTS.file.maxweight * 1024 * 1024) {
                    this.myForm.get('file').setErrors({'maxweight':true});
                    this.myForm.get('file').setValue(null);

                } else if (!FU.isAcceptedFileFormat(file.type) ) {
                    this.myForm.get('file').setErrors({'badformat':true});
                    this.myForm.get('file').setValue(null);
                } else {
                    fileReq = {
                        name: file.name,
                        filecontent: (reader.result as string).split(',')[1], // warning : ok only while only one doc
                    };

                    this.myForm.get('file').setValue(fileReq);
                    this.myForm.get('file').setErrors(null);

                    // shows file name in preview, and if picture , shows preview of picture
                    document.querySelector(`#file-preview-${this.unicizer}`).innerHTML =
                        FU.isPicture(file.type) ? `<img src="${reader.result}"><span>${file.name}</span>` : `<span>${file.name}</span>`;
                }

                if (this.parentId == null) { // only in creation context, emit a documentChange event
                    this.documentChanged.emit({invalid: this.myForm.invalid, item: fileReq})
                }
            };
        }
    }

    clearFile(): void {
        this.myForm.get('file').setValue(null);
        //this.fileInput.nativeElement.value = '';
        this.elementRef.nativeElement.querySelector(`#file-input-${this.unicizer}`).value = '';
        this.elementRef.nativeElement.querySelector(`#file-preview-${this.unicizer}`).innerHTML = '';
        if (this.parentId == null) { // only in creation context, emit a documentChange event
            this.documentChanged.emit({invalid: false, item: null});
        }
    }

    // Submitting the document is enabled only if parent item exists (parent item UPDATE context)
    onSubmit(): void {
        this.submitting = true;
        this.documentService.createDocument(this.parentType, this.parentId, this.myForm.get('file').value)
            .subscribe(
                (data: Document) => {
                    this.submitting = false;
                    //this.alertService.success('common.document.message.okUploaded'); //NO: overloads UX
                    this.clearFile();
                    this.documentUploaded.emit({action:ActionEnum.CREATE, item: data});
                },
                error => {
                    this.submitting = false;
                    //this.clearFile(); // better UX is to keep current file, not to clear it
                }
            );
    }

}
