/*
 * Author EFWAY / F. Delaunay
 */
import { Component, OnInit } from '@angular/core';
//import { NavigationService } from '../../_core/services/navigation.service';

@Component({
  templateUrl: './page-forbidden.component.html',
})
export class PageForbiddenComponent implements OnInit {
    constructor() {}

    ngOnInit(){}
}
