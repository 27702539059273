/*
 * Cancel/Confirm modal;
 * Input = content (as mat-dialog-data) as i18 code, optionaly confirm and cancel buttons as i18n codes
 * Clicking the confirm button sends a {ok: true} feedback
 * Closing or cancelling sends no feedback
 * If title is not provided, no title displayed
 * If buttons texts are not provided, default texts are used
 * Author EFWAY / F. Delaunay
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ConfirmDialogData } from '@src/app/_shared/modals/modals.interfaces';

@Component({
    selector: 'wid-confirm-dialog',
    templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
    ) {}

    ngOnInit(): void { }

    onClose(): void {
        this.dialogRef.close();
    }
    onCancel(): void {
        this.dialogRef.close();
    }
    onConfirm(): void {
        this.dialogRef.close({ok: true})
    }

}
