/*
 * Author EFWAY - F. DELAUNAY
 */


import { MatDialogConfig } from '@angular/material/dialog';
import { Viewport } from '@src/app/_core/services/viewport.service';
import { CSS } from '@src/app/_core/defs/app.defs';

/*
 * Utilities for MatDialog configurations
 */
export class DialogUtilities {

    static modalSimpleConfig(): MatDialogConfig {
        return {
            width: '100vw',
            maxWidth: '520px',
            autoFocus: false,
            panelClass:['wid-simple-modal']
        }
    }

    static modalFormConfig(viewport: Viewport, maxWidth:number = CSS.wForm): MatDialogConfig {
        // All are used as basic modal popup
        // They are assumed to ALWAYS wrap content inside a container like mat-card,  that adds padding => dialog box shall have none
        // They also are assumed to position a close button

        return {
            position: {top: viewport.isSmallDown ? `${CSS.hHeaderMobile}px` : '10vh'}, //1vh
            // - pas de minW, sinon  pb d'élasticité dans on redimensionne l'écran en partant de width > small -> largeur du contenu par css
            // - on force le maxW sinon il est mis par défaut à 80vw par Material
            width: '100vw',
            maxWidth: `${maxWidth}px`,
            maxHeight: viewport.isSmallDown ? `calc(100vh - ${CSS.hHeaderMobile}px)` : '80vh',
            panelClass: ['nopadding'], //, 'wid-closable-container'
            autoFocus: false,
        }
    }


    static modalPreviewConfig(viewport: Viewport, maxWidth:number = CSS.wForm): MatDialogConfig {
        // idem
        return {
            position: {top: viewport.isXSmall ? `${CSS.hHeaderMobile}px`: '5vh'},
            width: '100vw',
            maxWidth: `${maxWidth}px`,
            ////
            minHeight: '40vh', // pour réduire le flash à l'ouverture lié au temps de chargement de la liste
            maxHeight: viewport.isXSmall ? `calc(100vh - ${CSS.hHeaderMobile}px)` : '90vh',
            height: '100vh',
            panelClass: ['nopadding'], //, 'wid-closable-container'
            autoFocus: false,
        }
    }
}
