/*
 * QEOPS - WIDIP Software 2020
 * Author EFWAY / F. Delaunay
 *
 * App configuration data - a very optional all-in-one file, only in order to simplify imports
 * (Constants that are component-or-module specific are defined at component or module level)
 */

export { DEFAULTS } from './_defaults.defs';
export { ASSERTS } from './_asserts.defs'
export { ACL } from './_acl.defs';
export { CSS } from './_css.defs';
export { PATHS } from './_paths.defs';
export { PAGE_TITLES } from './_page-titles.defs'
export { MENU } from './_menu.defs';
export { SECTION_ITEMS } from './_section-items.defs'












