/*
 * QEOPS - (c) WIDIP 2021
 * Author EFWAY / F. Delaunay
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment as env } from '@src/environments/environment';
import { Location } from '@src/app/_core/model/location.model';
import { ItemTypeEnum } from '@src/app/_core/interfaces/rest-api.interfaces';
import { Sort, Pagination, PaginatedList } from '@src/app/_core/interfaces/app.interfaces';
import { ResourceQualifier, RestApiService } from '@src/app/_core/services/rest-api.service';

@Injectable({
    providedIn: 'root',
})
export class LocationService {

    private resource: ResourceQualifier = {
        itemType: ItemTypeEnum.LOCATION,
        parentType: ItemTypeEnum.ORGANIZATION,
    };

    constructor(
        private restApiService: RestApiService,
    ) {}

    getLocationList(orgId: number, filter?: null, sort?:Sort, pagination?:Pagination): Observable<PaginatedList> {
        return this.restApiService.searchItems<Location>({...this.resource, parentId: orgId}, filter, sort, pagination).pipe(
            map((response: PaginatedList) => {
                return <PaginatedList>{
                    total: response.total,
                    items: (response.items||[]).map(item => new Location(item))
                } })
        );
    }

    getLocation(id: number): Observable<Location> {
        return this.restApiService.getItem<Location>({...this.resource, itemId: id}).pipe(
            map((response) => new Location(response))
        );
    }

    createLocation(orgId: number, item: Location): Observable<Location> {
        return this.restApiService.postItem<Location>({...this.resource, parentId: orgId}, item.buildBeRequest()).pipe(
            map((response) => new Location(response))
        );
    }

    updateLocation(item: Partial<Location>): Observable<number> {
        return this.restApiService.putItem<Location>({...this.resource, itemId: item.id}, item.buildBeRequest());
    }

    deleteLocation(id: number): Observable<any> {
        return this.restApiService.deleteItem({...this.resource, itemId: id});
    }

}
