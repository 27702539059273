/*
 * Manages displaying and downloading a document
 * Author EFWAY - F. Delaunay
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'; // pour download sécurisé des fichiers
import { saveAs } from 'file-saver';

import { FileUtilities } from '@src/app/_core/utils/file-utilities';
import { ErrorService } from '@src/app/_core/services/error.service';
import { AlertService } from '@src/app/_core/services/alert.service';
import { ItemTypeEnum } from '@src/app/_core/interfaces/rest-api.interfaces';
import { DocumentService } from '@src/app/_shared/document/document.service';
import { Document } from '@src/app/_core/model/document.model';
import { ActionFeedback, ActionEnum } from '@src/app/_core/interfaces/app.interfaces';

/*
 * Document component :
 * Notice : presently it is NO worth trying to merge with CNIL's Attachments because they have too many specificities
 */
@Component({
    selector: 'wid-document',
    templateUrl: './document.component.html',
    styleUrls:['./document.component.scss']
})
export class DocumentComponent implements OnInit {
    @Input() document: Document;
    @Input() parentType: ItemTypeEnum;
    @Input() parentId?: number = null;
    @Input() isDeletable?: boolean = false;

    @Output() documentDeleted = new EventEmitter<ActionFeedback<Document>>();

    fileUrl: any;
    isPicture: boolean;
    loading: boolean = false;
    loadingImg: boolean = false;

    constructor(
        private domSanitizer: DomSanitizer,
        private errorService: ErrorService,
        private alertService: AlertService,
        private documentService: DocumentService
    ) { }

    ngOnInit(): void {
        this.isPicture = FileUtilities.isPicture(this.document.mimetype);

        if (this.isPicture) {
            this.loadingImg = true;
            this.documentService.downloadDocumentFile(this.parentType, this.parentId, this.document.id).subscribe(
                data => {
                    this.loadingImg = false;
                    // Transforms file data in a local object, get a local url and passes it through the domSanitizer so angular knows he can parse it
                    this.fileUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data));
                    },
                (error) => {
                    this.loadingImg = false;
                    }
            );
        }
    }

    downloadAndSaveFile(): void {
        this.loading = true;
        this.documentService.downloadDocumentFile(this.parentType, this.parentId, this.document.id).subscribe(
            data => {
                saveAs(data, this.document.name);
                this.loading = false;
            },
            error => {
                this.loading = false;
            }
        );
    }

    confirmDeleteDocument(): void {
        //todo gérer la confirmation
        this.deleteDocument();
    }

    private deleteDocument(): void {
        this.documentService.deleteDocument(this.parentType, this.parentId, this.document.id).subscribe(
            (response) => {
                //this.alertService.success('common.document.message.okDeleted');
                this.documentDeleted.emit({action: ActionEnum.DELETE, item: this.document});
            },
            (error) => {
                this.alertService.error('common.document.message.nokDeleted');
            }
        )
    }

}
