<!--
 * by EFWAY
 * Adaptation of sk-circle and of "sk bounce" that both come from SpinKit source (http://tobiasahlin.com/spinkit/)
-->
<div *ngIf="show" class="fwy-spinner-overlay">
    <!-- Currently, "fwy-spinnerr" is factorizable for the two spinners type; this may change whenever additional types come -->
    <div class="fwy-spinner"
         [style.width.px]="width" [style.height.px]="height"
         [style.top]="top" [style.bottom]="bottom"
         [style.left]="left" [style.right]="right"
         [class.bgwhite]="theme=='white'"
    >
      <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'uploader'">
          <div class="sk-bounce1 sk-bounce" [style.width.px]="height" [style.height.px]="height" ></div>
          <div class="sk-bounce2 sk-bounce" [style.width.px]="height" [style.height.px]="height" ></div>
          <div class="sk-bounce3 sk-bounce" [style.width.px]="height" [style.height.px]="height" ></div>
        </ng-container>

        <ng-container *ngSwitchDefault>
            <div class="sk-circle1 sk-circle"></div>
            <div class="sk-circle2 sk-circle"></div>
            <div class="sk-circle3 sk-circle"></div>
            <div class="sk-circle4 sk-circle"></div>
            <div class="sk-circle5 sk-circle"></div>
            <div class="sk-circle6 sk-circle"></div>
            <div class="sk-circle7 sk-circle"></div>
            <div class="sk-circle8 sk-circle"></div>
            <div class="sk-circle9 sk-circle"></div>
            <div class="sk-circle10 sk-circle"></div>
            <div class="sk-circle11 sk-circle"></div>
            <div class="sk-circle12 sk-circle"></div>
        </ng-container>
      </ng-container>
    </div>
</div>
