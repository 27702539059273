import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { User } from "@src/app/_core/model/user.model";
import { TimeUtilities as TU } from "@src/app/_core/utils/time.utilities";

@Pipe({ name: "nl2br" })
export class Nl2brPipe implements PipeTransform {
    constructor() {}
    transform(value: string) {
        return `${value}`.replace(/\n/g, "<br>");
    }
}

@Pipe({ name: "widDate" })
export class WidDatePipe implements PipeTransform {
    constructor() {}

    transform(value: Date, user?: User, format?) {
        return TU.widFormatDate(value, user, format);
    }
}

@Pipe({ name: "safeHtml" })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}

    transform(value): SafeHtml {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}
