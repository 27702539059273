/*
 * Simple information modal with an acknowledgement button ;
 * If title is not provided, no title displayed
 * If button text is not provided, default text is used
 * Clicking the button sends a {ok: true} feedback because it may be used by the caller
 * Author EFWAY / F. Delaunay
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'wid-info-dialog',
    templateUrl: './info-dialog.component.html'
})
export class InfoDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<InfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {content: string, button?:string; title?: string; bottomContent?:string }
        ) {}

    ngOnInit(): void { }

    onClose(): void {
        this.dialogRef.close();
    }
    // onAck(): void {
    //     this.dialogRef.close({ok: true})
    // }

}
