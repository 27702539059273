/*
 * (c) EFWAY 2018-2022
 * Author: EFWAY / F. Delaunay
 */

import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChange
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";

import { StringUtilities as SU } from "@src/app/_core/utils/string-utilities";
import { ListUtilities as LU } from "@src/app/_core/utils/list-utilities";
import {
    ActionEnum,
    MetaEditionFeedback
} from "@src/app/_core/interfaces/app.interfaces";
import { MatFormFieldAppearance } from "@angular/material/form-field";

//== Usefull only with 'single selection' pattern
// class UserValidator { // checks that value is a User object
//     static userInstanceRequired(control: AbstractControl) {
//         return control.value instanceof User ? null : {'userInstanceRequired': true}
//     }
//== }

/*
 * Field for selection of multiple items with an autocompletion list and selected items as removable chips
 *    obviously : an item is selectable only once
 * Attempt to make it a bit generic, ASSUMING :
 *   items are objects with an id
 *   autocompletion list is filtered by rule:  "item.searchProperty contains searchString"
 */
@Component({
    selector: "fwy-chip-autocompletion-field",
    templateUrl: "chip-autocompletion-field.component.html",
    styleUrls: ["./chip-autocompletion-field.component.scss"]
})
export class ChipAutocompletionFieldComponent implements OnInit, OnChanges {
    @Input() list: any[] = []; // reference list of items that will be filtered with input on searchProperty ; items are intended to have an id
    @Input() loadingList: boolean = false;

    // 15/01/2022: for backward-compatibility, "searchProperty" input is not renamed despite extended to array
    // The Component no more use it except in ngInit to initialize "searchProperties" as a pure array:
    @Input() searchProperty: string | string[]; // name(s) of the item property(ies) used to filter the list
    searchProperties: string[];

    @Input() selectedItems: any[] = []; // will be updated by this component
    @Input() excludedItems: any[] = []; // for  special case of mutual exclusion between 2 chip fields catered with the same list
    @Input() labelI18n: string;
    @Input() placeholderI18n: string = "common.action.addChip";
    @Input() required: boolean = false; // changed 08/2022
    @Input() requiredHighlight: boolean = false; // when true, apply the app-field-highlight class if required and empty
    @Input() appearance: MatFormFieldAppearance = "outline"; // mat-field "appearance"
    @Input() infostickerI18n: string = null; // if set, add a <fwy-info-sticker>
    @Input() displayItemInChipFn: (item: any) => string = this
        .defaultDisplayItemInChipFn;
    @Input() displayItemInListFn: (
        item: any,
        searchString?: any
    ) => string = this.defaultDisplayItemInListFn;

    @Output() selectionChanged = new EventEmitter<MetaEditionFeedback<any[]>>();

    filter: FormControl = new FormControl(); // just the mat-input to enter the searchstring that filters the autocompletion list
    listFiltered$: Observable<any[]>;

    @ViewChild("filterInput") filterInput: ElementRef;

    constructor() {}

    ngOnChanges(changes: { [propKey: string]: SimpleChange }): void {
        if (
            changes["excludedItems"] &&
            !changes["excludedItems"].isFirstChange()
        ) {
            //&& !changes['excludedItems'].isFirstChange() ) {
            this.filter.setValue(this.filter.value); // trick to trigger a change
        }
        if (changes["required"] && !changes["required"].isFirstChange()) {
            this.selectionChanged.emit({
                item: this.selectedItems.length ? this.selectedItems : null,
                invalid: this.required && !this.selectedItems.length
            });
        }
    }
    ngOnInit(): void {
        this.searchProperties =
            typeof this.searchProperty === "string"
                ? [this.searchProperty as string]
                : (this.searchProperty as string[]);
        // filter selection list according input search text, and also excluding already selected items
        this.listFiltered$ = this.filter.valueChanges.pipe(
            map(stringOrItem => {
                const alreadySelectedList = this.selectedItems || [];
                // we enter a search string BUT Angular set back input value as the selected item

                const searchString: string =
                    typeof stringOrItem === "string"
                        ? stringOrItem
                        : stringOrItem?.[this.searchProperties[0]]; // borderline, but should work

                if (searchString) {
                    return this.list
                        ?.filter(
                            o =>
                                !alreadySelectedList?.find(s => s.id == o.id) &&
                                !this.excludedItems?.find(e => e.id == o.id)
                        )
                        ?.filter(o => {
                            let match: boolean = false;
                            this.searchProperties.forEach(prop => {
                                match =
                                    match ||
                                    (o[prop] as string)
                                        .toLowerCase()
                                        .includes(searchString.toLowerCase());
                            });
                            return match;
                        });
                } else {
                    return this.list.filter(
                        o =>
                            !alreadySelectedList.find(s => s.id == o.id) &&
                            !this.excludedItems?.find(e => e.id == o.id)
                    );
                }
            })
        );
    }

    defaultDisplayItemInChipFn(item: any): string {
        let label: string = item?.[this.searchProperties[0]];
        // If 2 or more properties: add the second only, in brackets and if not null
        if (this.searchProperties.length >= 2) {
            if (item?.[this.searchProperties[1]]) {
                label = `${label || ""} (${item?.[this.searchProperties[1]]})`;
            }
        }
        return label || undefined;
    }
    /**
     * default display of an item in search list : display the searchProperty, highlighting searchString in searchProperty
     */
    defaultDisplayItemInListFn(item: any, searchString: any): string {
        const label = this.defaultDisplayItemInChipFn(item) || "";
        return SU.highlightSearchText(label, searchString);
    }

    clearInput(event: MatChipInputEvent): void {
        this.filterInput.nativeElement.value = null;
    }

    onRemoved(item: any): void {
        LU.updateList(this.selectedItems, item, ActionEnum.DELETE);
        this.selectionChanged.emit({
            item: this.selectedItems.length ? this.selectedItems : null,
            invalid: this.required && this.selectedItems.length == 0
        });
        this.filter.setValue(null);
    }

    onSelected(event: MatAutocompleteSelectedEvent): void {
        this.selectedItems.push(event.option.value);
        this.selectionChanged.emit({
            item: this.selectedItems.length ? this.selectedItems : null,
            invalid: this.required && !this.selectedItems.length
        });

        this.filterInput.nativeElement.value = null;
        this.filter.setValue(null);
    }

    onFocus() {
        this.filter.setValue(null);
    }
    onClick() {
        console.log("onclick");
    }
}
