<mat-form-field
    class="fwy-spinner-container"
    style="width:100%;"
    [appearance]="appearance"
    [ngClass]="{
        'ng-dirty': filter.dirty,
        'ng-invalid': required && !selectedItem,
        'mat-form-field-invalid': required && !selectedItem,
        'ng-touched': filter.touched
    }"
>
    <fwy-spinner [show]="loadingList" [xPosition]="'right'"></fwy-spinner>
    <mat-label
        >{{ labelI18n | translate }} <span *ngIf="required">*</span></mat-label
    >
    <!-- le * est un sale workaround du pb Angular qui ne VEUT pas l'afficher  -->

    <input
        #filterInput
        [formControl]="filter"
        placeholder="{{ placeholderI18n | translate }}"
        matInput
        [matAutocomplete]="acForInput"
        #searchStringTrigger="matAutocompleteTrigger"
        [(ngModel)]="selectedItem"
        (keyup.enter)="onKeyEnter($event)"
    />
    <!-- (click)="searchStringTrigger.openPanel()" (blur)="onSubmit()"  (focus)="onFocus()"-->
    <mat-icon matSuffix>search</mat-icon>

    <!-- the autocompletion selection list, filtered according input--->
    <mat-autocomplete
        #acForInput="matAutocomplete"
        panelWidth="auto"
        [displayWith]="displayItemFn.bind(this)"
        (optionSelected)="onSelected($event)"
    >
        <mat-option *ngIf="nullOption" [value]="null"></mat-option>
        <mat-option
            *ngFor="let item of listFiltered$ | async"
            [class]="optionsClass || ''"
            [value]="item"
            [innerHTML]="
                displayItemInListFn(item, filterInput.value) | safeHtml
            "
        >
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
