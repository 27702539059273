/**
 * QEOPS - (c) Widip Software 2021
 * Author: EFWAY / F. Delaunay
 */

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ErrorService } from '@src/app/_core/services/error.service';
import { Location } from '@src/app/_core/model/location.model';
import { ActionEnum, ActionFeedback } from '@src/app/_core/interfaces/app.interfaces';
import { LocationService } from '@src/app/_shared/location/location.service';

/*
 * Location deletion dialog box
 */
@Component({
    templateUrl: './location-delete-dialog.component.html'
})
export class LocationDeleteDialogComponent implements OnInit {

    submitting: boolean = false;
    constructor(
        private locationService: LocationService,
        private errorService: ErrorService,
        public dialogRef: MatDialogRef<LocationDeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {item: Location}) {}

    ngOnInit(): void {
    }

    onSubmit(): void {
        this.submitting = true;
        this.locationService.deleteLocation(this.data.item.id).subscribe(
            (ok) => {
                this.submitting = false;
                this.dialogRef.close(<ActionFeedback<Location>>{action: ActionEnum.DELETE, item: this.data.item});
            },
            (error) => {
                this.errorService.handleError(error);
                this.submitting = false
            }
        )
    }
    onCancel(): void {
        this.dialogRef.close();
    }

}
