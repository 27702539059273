
// Common fields validation asserts
// todo continue centralizing assertions here ... and use them
export const ASSERTS = {
    // generic
    xxsText: { maxlength: 40},
    xsText: { maxlength: 80},
    shortText: { maxlength: 160},
    longText: {maxlength: 1000},
    xlText: { maxlength: 2000},

    // fields
    password: {
        minlength: 8,
        maxlength: 100,
        // 8 to 100 chars with a lowercase, an uppercase, a digit and a special char:
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*["!#$%&'§()*+,-./:;<=>?@\[\]^_`{|}~])[A-Za-z\d"!#$%&'§()*+,-./:;<=>?@\[\]^_`{|}~]{8,100}$/, // /^[^ ]{8,100}$/ -> 8 to 100 char., any char but the blank space
    },
    email: {
        pattern: /[^@\s]+@[^@\s]+\.[^@\s]+$/,   // not RFC but pretty realistic and better that the angular one
        maxlength: 100                          // theoretical max is 254 but 100 is a practical one
    },
    firstname: { maxlength: 50 },
    lastname: { maxlength: 50 },
    name: { maxlength: 50 },        // = person lastname or company name
    address: { maxlength: 100 },
    zipcode: { maxlength: 5 },
    city: { maxlength: 50 },
    country: { maxlength: 50 },
    phone: { maxlength: 40 },   // some people are used to enter 2 phone numbers, or some text
    file: {
        maxweight: 20, // in MB
    },
    searchString: { // for filters
        maxlength: 40
    }
};
