/*
 * Author (c) EFWAY / F. Delaunay
 */

import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of as observableOf } from 'rxjs';

import { environment as env } from  '@src/environments/environment';
import { SESSION_EXPIRED_MSG, PASSWORD_CHANGE_MSG, PASSWORD_CHANGE_FIRSTTIME_MSG } from '@src/app/_core/interfaces/rest-api.interfaces';
import { AlertService } from '@src/app/_core/services/alert.service';
import { AuthenticationService } from '@src/app/_core/services/authentication.service';

/**
 * A common service that handles errors
 */
@Injectable({
    providedIn: 'root',
})
export class ErrorService {

    constructor(
        private alert: AlertService,
        private authService: AuthenticationService
        ) {
    }

    public handleError(error: any, opt?:{loginContext: boolean}): void {
        let errMsg: string; //i18n text code for the end-user

        if (error instanceof HttpErrorResponse) {
            env.log && console.error('Error is instanceof "HttpErrorResponse", error=' + JSON.stringify(error));

            if (error.status||0) { // Server answered
                // As a general rule :
                //    Display the message "as-is" if found (it is expected to be in the appropriate user's language)
                //    else display a generic message
                // But they are some exceptions :
                //   err 500 -> ensure no tech details are displayed
                //   login context -> we cannot use the msg "as-is" at this step because it might not use the user's device language

                switch (error.status) {
                    case 500: { // server error
                        // whatever the server returns (details or not) we do not want to give tech details to the end user
                        errMsg = 'common.http_error.err_generic';
                        break
                    }
                    case 404: {
                        errMsg = 'common.http_error.err_pageNotFound';
                        break;
                    }
                    case 403: { // rights issue
                        //errMsg = error.error.message ? error.error.message : 'common.errors.err_forbidden';
                        errMsg = 'common.http_error.err_forbidden';
                        break;
                    }
                    case 401: { // authentication failure (bad login or password)
                                // ( OR session token has expired -> NO MORE, thanks to the auth-refresh-interceptor)
                                // OR refresh_token has expired
                        // well, these are various contexts and customizing backend's error messages is tough and considering them as i18n messages is dubious
                        // So we try to distinguish them by other means, in order to display a controlled message, and to eventually log out
                        //  -> we distinguish login context thanks to opt.loginContext
                        //  -> session token expiration is intercepted by auth-refresh-interceptor, which attempts to refresh it
                        //        if it succeeds, there is no error at all and this piece of code is not reached
                        //        but it can fail, because the refresh_token can itself have expired
                        //                |-> we consider we are in this situation if the error msg is different from the SESSION_EXPIRED_MSG
                        if (opt?.loginContext) {
                            errMsg = error.error?.message ? error.error.message : 'common.http_error.err_badCredentials';
                        } else {
                            errMsg =  error.error?.message ? error.error.message : 'common.http_error.err_authentication';
                            if (errMsg.toUpperCase() != SESSION_EXPIRED_MSG.toUpperCase() && errMsg != 'common.http_error.err_authentication' ) {
                                errMsg = 'common.http_error.err_invalidToken';
                            }
                            this.authService.logout().subscribe();
                        }
                        break;
                    }
                    case 400:  { // invalid data have been provided to the backend
                        errMsg = error.error?.message ? error.error.message : 'common.http_error.err_badRequest';
                        break;
                    }
                    default: {
                        errMsg= error.error?.message ? error.error.message : 'common.http_error.err_generic';
                        break;
                    }
                }

            } else { // status 0 = unknown  http error
                errMsg = 'common.http_error.err_httpUnknown'; // very probably a connectivity issue
            }

        } else if (error.error instanceof Error) { // A client-side or network error occurred. Handle it accordingly.
            env.log && console.log('Error is instanceof "Error", error=' + error.error.message);
            errMsg = 'common.app_error.err_generic';
            //errTechMsg = `(${error.error && error.error.errorMessage ? error.error.errorMessage})`;

        } else if (error.error instanceof ErrorEvent) { // client-side (?)
            env.log && console.log('Error is instanceof "ErrorEvent", error=' + error.error.message);
            errMsg = 'common.app_error.err_generic';

        } else if (typeof error === 'string' || error instanceof String) {
            // string comes from the caller App component and should be a i18n message code
            errMsg = `${error}`;
        }
        else {
            env.log && console.error('Error is neither instanceof HttpErrorResponse,  Error nor ErrorEvent, error=' + JSON.stringify(error));
            errMsg = error.error ? error.error.message : 'common.app_error.err_generic';
        }

        this.alert.error(errMsg);
    }

    /**
     * Return reset key and firstTime boolean when error is 401 with an error message about changing password
     */
    public getDataWhenForcePasswordError(error: any): {key:string, firstTime: boolean } {
        if (error instanceof HttpErrorResponse && (error?.status||0) == 401) {
            if (error.error?.message == PASSWORD_CHANGE_MSG) {
                return {key: error.error.key, firstTime: false}
            } else if (error.error?.message == PASSWORD_CHANGE_FIRSTTIME_MSG) {
                return {key: error.error.key, firstTime: true}
            }
        }
        return null; // other cases: not a 401 http error and not a passwordChange* error message
    }
    /*
    * Method to call from an observable pipe in order to handle the error while let the app keep running by returning an empty result
    */
    public handleErrorSeamlessly<T> (result: T) {
        return (error: any): Observable<T> => {
            this.handleError(error);
            return observableOf(result as T);
        };
    }

    public buildHttpError(codeHttp:number, message: string): HttpErrorResponse {
        return new HttpErrorResponse({status: codeHttp, error: {message: message}});
    }
}
