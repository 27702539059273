/*
 * Modal as Tooltip
 * Author EFWAY / F. Delaunay
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/*
 * todo: (vu trop tard) en fait ce composant est inutile car mat-menu fait l'affaire pour le remplacer
 */
@Component({
    selector: 'wid-modal-as-tooltip',
    templateUrl: './modal-as-tooltip.component.html'
})
export class ModalAsTooltipComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ModalAsTooltipComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {content: string }
    ) {}

    ngOnInit(): void {}

    close(): void {
        this.dialogRef.close();
    }

}
