<!-- le mat-icon-button reste auréolé quand on revient d'un clic depuis la dialog-popup (sans doute à cause du stopPropagation())
<button mat-icon-button
        style="color: rgba(204,204,204, 0.8);"
        (click)="openTooltipModal($event)">
    <mat-icon style="font-size: 18px;">info</mat-icon>
</button>
-->

<span class="wid-button-like wid-clickable wid-btn-info"  (click)="openTooltipModal($event)">
   <mat-icon>info</mat-icon>
</span>

<!--
<button mat-icon-button [matMenuTriggerFor]="help" style="color: rgba(204,204,204, 0.8);" (click)="$event.stopPropagation();">
   <mat-icon style="font-size: 18px;">info</mat-icon>
</button>

<mat-menu #help="matMenu" [overlapTrigger]="true" [hasBackdrop]="true" [backdropClass]="'opacifier'">
   <div style="padding: 4px 16px" [innerHTML]="content|nl2br"></div>
</mat-menu>
-->
