<form [formGroup]="myForm" novalidate >
    <!----- Document ----->
    <div class="wid-form-field">
        <div> <!-- this div prevents width 100% -->
            <div style="display:flex; justify-content: flex-start; align-items: center;">
                <label for="file-input-{{unicizer}}" class="fwy-btn-pickuper waves-effect waves-light">
                    {{'common.document.field.labelShort'|translate: {maxweight: ASSERTS.file.maxweight} }}
                </label>
                <input type="file" id="file-input-{{unicizer}}"
                       (change)="onFileChange($event)"
                       style="width:0; opacity:0;"
                >

                <div class="wid-file-preview-container" [class.wid-disabled]="submitting">
                    <div id="file-preview-{{unicizer}}" class="wid-file-preview"></div>
                    <mat-icon *ngIf="!!myForm.get('file').value" class="wid-cancel" (click)="clearFile()">close</mat-icon>
                </div>

                <!-- Action button for upload - ONLY if item exists--> <!-- mat-icon-button -->
                <button *ngIf="parentId !== null && !!myForm.get('file').value"
                        mat-raised-button color="accent" type="submit"
                        matTooltip="{{'common.document.action.submit'|translate}}"
                        class="fwy-spinner-container"
                        [class.submitting]="submitting"
                        [disabled]="myForm.invalid  || !myForm.get('file').value"
                        (click)="onSubmit()">
                    <fwy-spinner [show]="submitting" type="uploader" [xPosition]="'left'" [xPositionAdjust]="'10px'"></fwy-spinner>
                    <!--<mat-icon>send</mat-icon>-->
                    {{'common.document.action.submit'|translate}}
                    <mat-progress-bar *ngIf="submitting"
                                      style="position:absolute; bottom:0; left:0; width:100%; "
                                      color="primary"
                                      mode="determinate"
                                      [value]="uploadProgress$ | async">
                    </mat-progress-bar>
                </button>
            </div>

            <div class="wid-field-help-wrapper">
                <div *ngIf="myForm.get('file').hasError('badformat')" class="wid-help error">
                    {{'common.document.field.err_badFormat'|translate}}
                </div>
                <div *ngIf="myForm.get('file').hasError('maxweight')" class="wid-help error">
                    {{'common.document.field.err_maxWeight'|translate:{maxweight: ASSERTS.file.maxweight} }}
                </div>
            </div>
        </div>
    </div>


</form>
