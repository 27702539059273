/*
 * Author EFWAY / F. Delaunay
 */
import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent implements OnInit {
    constructor() {}

    ngOnInit(){ }
}
