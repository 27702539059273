<!-- <div> -->
    <div class="wid-closable-container padded">
        <span class="wid-action wid-close" (click)="onCancel()" >
            <mat-icon>close</mat-icon>
        </span>
        <mat-card-title class="wid-closable-container-header">
            <ng-container>
                {{(data.contact ? 'contact.edit.titleUpdate' : 'contact.edit.titleCreate')|translate}}
                <span *ngIf="!!data.contact">[{{data.contact.displayFullname()}}]</span>
            </ng-container>
        </mat-card-title>

            <wid-contact-form [contact]="data.contact"
                              [isCompany]="data.isCompany"
                              (contactChanged)="onContactChanged($event)">
            </wid-contact-form>

            <!-- Actions button -->
            <div class="wid-form-actions">
                <button mat-raised-button color="accent" type="submit"
                        class="fwy-spinner-container"
                        [class.submitting]="submitting"
                        [disabled]="myForm.invalid"
                        (click)="onSubmit()">
                    <fwy-spinner [show]="submitting" type="uploader" [xPosition]="'left'" [xPositionAdjust]="'10px'"></fwy-spinner>
                    {{(!!data.contact ? 'contact.edit.action.update' : 'contact.edit.action.create')|translate}}
                </button>
                <a mat-button (click)="onCancel()">
                    {{'contact.edit.action.cancel'|translate}}
                </a>
            </div>
</div>
