
<h2 mat-dialog-title>
    {{'location.delete.title'|translate:{name: data.item.name} }}
</h2>

<div mat-dialog-content>
    <p style="text-align: center;" [innerHTML]="'location.delete.message'| translate"></p>
</div>

<!--<div mat-dialog-actions>-->
<div class="wid-form-actions dialog">
    <button mat-raised-button color="accent" type="submit"
            class="fwy-spinner-container"
            (click)="onSubmit()">
        <fwy-spinner [show]="submitting" type="uploader"></fwy-spinner>
        {{'location.delete.action.submit'|translate}}
    </button>

    <button mat-button (click)="onCancel()">
        {{'location.delete.action.cancel'|translate}}
    </button>
</div>
<!--</div>-->
