/*
 * Time utilities methods
 * Author EFWAY - F. Delaunay
 */

import { formatDate } from '@angular/common';
import { DateRange } from '@src/app/_core/interfaces/app.interfaces';
import { User} from '@src/app/_core/model/user.model';

export interface WeekNum {
    year: number,
    week: number
}

export class TimeUtilities {

    static widFormatDate(date: Date, user?: User, format = 'dd/MM/yy'): string {
        return date ? formatDate(date, format, user?.locales??'fr-FR', 'Europe/Paris') : '';
    }

    /*
    * Format a Date to any format/tz, with default values for the format & tz
    * format specification is angular's DatePipe
    */
    static convertDateToString(date:Date, format?:string, locale?:string, tz?:string): string {
        return date ? formatDate(date, format? format:'dd/MM/yy', locale??'fr-FR', tz??'Europe/Paris') //format:'dd/MM/yyyy'
            : '';
    }

    static getMonthDates(date:Date):DateRange {
        const monthStart = new Date(date.getFullYear(), date.getMonth(), 1,0, 0,0, 0); // 1 mn clock shift margin
        const monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0,23, 59,59, 999);
        return {start:monthStart, end: monthEnd}
    }

    /*
     * Calculates the number of days between 2 datetimes
     * With signed = true, returns a negative value if end < start rather than the default absolute value
     * With withTime = true, uses Math.round rather than the default Math.floor ; this will take times into account
     * example: days between 01/01/2019 00:00 and 02/01/2019 23:59
     *      1 with default, 2 if withTime
     */
    static dayCountBetweenDates(dateRange:DateRange, opt:{signed?:boolean, withTime?:boolean}={signed:false, withTime:false} ): number {
        const res = ( dateRange.end.valueOf() - dateRange.start.valueOf() ) / 86400000; // get diff in days (1 day=86400000 ms)

        return opt.withTime ? Math.round(opt.signed ? res : Math.abs(res)) : Math.floor(opt.signed ? res : Math.abs(res));
    }

    /*
     * For a given date, get the ISO week number
     *
     * Fixed/adapted/enhanced from https://gist.github.com/dblock/1081513
     *
     * "Algorithm is to find nearest thursday, it's yearis the year of the week number. Then get weeks
     * between that date and the first day of that year.
     * Note that dates in one year can be weeks of previous or next year, overlap is up to 3 days."
     */

    static getWeekNumber(d:Date): WeekNum {
        const date = new Date(d.valueOf()); // clone d date

        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        date.setDate(date.getDate() + 4 - (date.getDay()||7));
        // Get first day of year
        const yearStart = new Date(date.getFullYear(),0,1, 0,0,0, 0);
        // Calculate full weeks to nearest Thursday
        const weekNum = Math.ceil(( ( (date.valueOf() - yearStart.valueOf()) / 86400000) + 1)/7);
        // Return array of year and week number
        return {year: date.getFullYear(), week: weekNum};
    }

    /*
     * For a given date, get monday & sunday dates of its week
     */
    static getWeekDates(d:Date): DateRange {
        //const monday = new Date(d.getFullYear(), d.getMonth(), d.getDate(),0,0,1);
        const monday = new Date(d.valueOf());
        monday.setDate( d.getDate() +(1 - (d.getDay() == 0 ? 7 : d.getDay())) ); //d.getDay() => 0 = sun to 6 = sat
        monday.setHours(0,0,0, 0);
        const sunday = new Date(monday.valueOf());
        //const sunday = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate(),23,59,59);
        sunday.setDate(monday.getDate() + 6);
        sunday.setHours(23,59,59, 0);
        return {start: monday, end: sunday};
    }

    static sameDay(d1:Date, d2: Date): boolean {
        return d1.getDate() == d2.getDate() && d1.getMonth() == d2.getMonth() && d1.getFullYear() == d2.getFullYear();
    }

    /*
    * Count can be with decimal: ex add 2.5 months to date
    *  We use a 30-day month base for the month decimal
    */
    static addMonthsToDate(d: Date, count: number): Date {
        const monthCount = Math.floor(count);
        const addDayCount = Math.floor((count - monthCount) * 30) ;
        // change 12/11/20 : DO NOT retrieve 1 day // addDayCount - 1
        return d ? new Date(d.getFullYear(), d.getMonth()+count, d.getDate() + addDayCount, 0, 0,0) : null; // clone d date

    }

//===========================================================================================================//
//=====================  Methods hereafter come from the PIA code by the CNIL ===============================//
//===========================================================================================================//

    static relativeSimpleDate(d:Date): string {
        const today = new Date();
        const date = new Date(d);
        const splitDate: any = this.relativeSplitDate(date);

        let res: string = null;

        if (splitDate.w < 1) {
            // Last 7 days
            res = 'Earlier in this month';

            // Current week
            if ( this.getWeekNumber(date).week == this.getWeekNumber(today).week) {
                res = 'Earlier in this week';
                // Today
                if (date.getDate() == today.getDate()) {
                    res = 'today';
                }
                // Yesterday
                if (date.getDate() ==  new Date(new Date().setDate(new Date().getDate() - 1)).getDate()) {
                    res = 'yesterday';
                }
            }
        } else if (splitDate.M < 1 && date.getMonth() == today.getMonth()) {
            // Last Month
            res = 'Earlier in this month';
        } else {
            // Other Month + Full year
            res = 'translate-month';
        }

        return res;
    }

    static relativeFullDate(d:Date): string {
        const splitDate:any = this.relativeSplitDate(d);

        return splitDate.s <= 1
            ? 'just now'
            : splitDate.m < 1
                ? Math.round(splitDate.s) + ' seconds ago'
                : splitDate.m <= 1
                    ? 'a minute ago'
                    : splitDate.h < 1
                        ? Math.round(splitDate.m) + ' minutes ago'
                        : splitDate.h <= 1
                            ? 'an hour ago'
                            : splitDate.d < 1
                                ? Math.round(splitDate.h) + ' hours ago'
                                : splitDate.d <= 1
                                    ? 'yesterday'
                                    : splitDate.w < 1
                                        ? Math.round(splitDate.d) + ' days ago'
                                        : splitDate.w <= 1
                                            ? 'last week'
                                            : splitDate.M < 1
                                                ? Math.round(splitDate.w) + ' weeks ago'
                                                : splitDate.M <= 1
                                                    ? 'last month'
                                                    : splitDate.y < 1
                                                        ? Math.round(splitDate.M) + ' months ago'
                                                        : splitDate.y <= 1
                                                            ? 'a year ago'
                                                            : Math.round(splitDate.y) + ' years ago';
    }

    private static relativeSplitDate(date: Date): any {
        const s = (+new Date() - date.valueOf()) / 1000;
        const m = s / 60;
        const h = m / 60;
        const d = h / 24;
        const w = d / 7;
        const y = d / 365.242199;
        const M = y * 12;

        return {s: s, m: m, h: h, d: d, w: w, y: y, M: M}
    }

    static agoFullDateWith18n(d:Date): {i18n: string; value?: number} {
        const splitDate:any = this.relativeSplitDate(d);
        const i18nBase = 'common.date.ago.';
        return splitDate.s <= 1
            ? {i18n: i18nBase+'justNow'}
            : splitDate.m < 1
                ? {i18n: i18nBase+'justNow', value: Math.round(splitDate.s)} // 'common.date
                : splitDate.m <= 1
                    ? {i18n: i18nBase+'oneMinuteAgo'}
                    : splitDate.h < 1
                        ? {i18n: i18nBase+'minutesAgo', value: Math.round(splitDate.m)}
                        : splitDate.h <= 1
                            ? {i18n: i18nBase+'oneHourAgo'}
                            : splitDate.d < 1
                                ? {i18n: i18nBase+'hoursAgo', value: Math.round(splitDate.h)}
                                : splitDate.d <= 1
                                    ? {i18n: i18nBase+'oneDayAgo'}
                                    : splitDate.w < 1
                                        ? {i18n: i18nBase+'daysAgo', value: Math.round(splitDate.d)}
                                        : splitDate.w <= 1
                                            ? {i18n: i18nBase+'oneWeekAgo'}
                                            : splitDate.M < 1
                                                ? {i18n: i18nBase+'weeksAgo', value: Math.round(splitDate.w)}
                                                : splitDate.M <= 1
                                                    ? {i18n: i18nBase+'oneMonthAgo'}
                                                    : splitDate.y < 1
                                                        ? {i18n: i18nBase+'monthsAgo', value: Math.round(splitDate.m)}
                                                        : splitDate.y <= 1
                                                            ? {i18n: i18nBase+'oneYearAgo'}
                                                            : {i18n: i18nBase+'yearsAgo', value: Math.round(splitDate.y)};
    }




}
