/*
 * QEOPS - (c) WIDIP Software
 * Author EFWAY F. Delaunay
 *
 */

import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { environment as env} from '@src/environments/environment';
import { ACL } from '@src/app/_core/defs/_acl.defs';
import { CanDo, ActionEnum, ActionFeedback } from '@src/app/_core/interfaces/app.interfaces';
import { DialogUtilities as DU } from '@src/app/_core/utils/dialog-utilities';
import { ListUtilities as LU } from '@src/app/_core/utils/list-utilities';
import { AclService } from '@src/app/_core/services/acl.service';
import { ViewportService} from '@src/app/_core/services/viewport.service';
import { Location } from '@src/app/_core/model/location.model';

import { LocationEditDialogComponent} from '@src/app/_shared/location/edit/location-edit-dialog.component';
import { LocationDeleteDialogComponent } from '@src/app/_shared/location/delete/location-delete-dialog.component';

interface LocationUI {
    location: Location,
    canDo: CanDo
}

@Component({
    selector: 'wid-location',
    templateUrl: 'location.component.html'
})
export class LocationComponent implements OnInit {
    @Input() selectedLocations: Location[] = [];
    @Output() actionFeedback = new EventEmitter<ActionFeedback<Location>>();

    locationUIList: LocationUI[] = []; // for homegeneity we keep here using our standard patterns although it is a bit artificial

    action:ActionEnum;
    actionEnum = ActionEnum;

    canCreate: boolean; // access to the CREATE button
    canDo: CanDo;

    shrinked: boolean = false;

    constructor(
        public dialog: MatDialog,
        private aclService: AclService,
        private viewportService: ViewportService,
    ) {}

    ngOnInit() {
        this.canCreate = this.aclService.canAccess(ACL.location_manage);
    }

    ngOnChanges(changes: {[propKey: string]: SimpleChange}): void {
        if ( changes['selectedLocations'] ) { //&& !changes['selectedLocations'].isFirstChange()
            this.locationUIList = this.buildUIList(this.selectedLocations);
        }
    }

    openEditLocationDialog(location: Location, action: ActionEnum): void {
        const dialogRef = this.dialog.open(LocationEditDialogComponent,
            { ...DU.modalFormConfig(this.viewportService.viewport), data: {item: location, action: action} });

        dialogRef.afterClosed().subscribe(
            (feedback: ActionFeedback<Location>) => {
                this.actionFeedback.emit(feedback);
            });
    }
    openDeleteLocationDialog(location: Location): void {
        const dialogRef = this.dialog.open(LocationDeleteDialogComponent,
            {...DU.modalSimpleConfig(), data: {item: location} });

        dialogRef.afterClosed().subscribe(
            (feedback: ActionFeedback<Location>) => {
                this.actionFeedback.emit(feedback);
            });
    }

    private buildUIList(locationList: Location[]): LocationUI[] {
        return locationList?.map(loc => {return {
            location: loc,
            canDo: this.initCanDo(loc)
        }});
    }
    private initCanDo(location: Location): CanDo {
        return {
            update: this.aclService.canAccess(ACL.location_edit),
            delete: this.aclService.canAccess(ACL.location_manage)
        }
    }

}
