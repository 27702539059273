/*
 * Author EFWAY / F. Delaunay
 */

import { Component, OnInit, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { DialogUtilities as DU } from "@src/app/_core/utils/dialog-utilities";
import { ModalAsTooltipComponent } from "@src/app/_shared/modals/modal-as-tooltip.component";

/*
 * Information "i" button to get a modal with help text
 * todo replace MatDialog by MatMenu
 */
@Component({
    selector: "wid-help-info",
    templateUrl: "./help-info.component.html"
})
export class HelpInfoComponent implements OnInit {
    @Input() content: string;

    constructor(private dialog: MatDialog) {}

    ngOnInit(): void {}

    openTooltipModal(event): void {
        event.stopPropagation(); // useful whenever info button is for instance inside a mat-expansion-panel-header (avoids toggling expansion)
        this.dialog.open(ModalAsTooltipComponent, {
            ...DU.modalSimpleConfig(),
            position: { top: `${event.pageY}px` },
            data: {
                content: this.content
            }
        });
    }
}
