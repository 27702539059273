import { Pagination } from '@src/app/_core/interfaces/app.interfaces';

// Defaults
export const DEFAULTS = {
    user: {
        lang: 'fr',
        country: 'FR',
    },
    pagination: {
        currentPage: 1,
        maxPerPage: 30
    } as Pagination
};
