/*
 * QEOPS - WIDIP Software 2020
 * Author EFWAY / F. Delaunay
 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment as env } from '@src/environments/environment';
import { DEFAULTS } from '@src/app/_core/defs/app.defs';
import { ItemTypeEnum } from '@src/app/_core/interfaces/rest-api.interfaces';
import { User } from '@src/app/_core/model/user.model';

import { AuthenticationService } from '@src/app/_core/services/authentication.service';
import { ResourceQualifier, RestApiService } from '@src/app/_core/services/rest-api.service';

/*
 * Session service :
 *     Initializes and persists Session User information
 *     Provides the session user value as direct-access value + as observable
 */
@Injectable({
    providedIn: 'root',
})
export class SessionUserService {

    private resource: ResourceQualifier = {
        itemType: ItemTypeEnum.USER,
        itemId: 'me'
    }

    user: User; // persists the authenticated user
    private newUserBS:BehaviorSubject<User> = new BehaviorSubject(null);
    newUser$ = this.newUserBS.asObservable();

    // Useful as long as user is not yet authenticated:
    //  first initialized with default values,
    //  then with values got from  user's device,
    //  [NOT WITH QEOPS: then with user's prefs at authentication (become useless at the same time, as included in this.user)]
    userLang: string = DEFAULTS.user.lang;
    userCountry: string = DEFAULTS.user.country;
    //

    constructor(
        private router: Router,
        private http: HttpClient,
        private translator: TranslateService,
        private authService: AuthenticationService,
        private restApiService: RestApiService
    ) {
        this.authService.isLogged$.subscribe(
            isLogged => {
                this.user = null; // reset at log out as well as at log in
                this.newUserBS.next(null);
            }
        );
    }

    getSessionUser(): Observable<User> {
        return this.restApiService.getItem<User>(this.resource).pipe(
            map((response) => {
                this.user = new User(response);
                this.updateGUILocales(); // if backend has provided lang & country, this will update local translations
                this.newUserBS.next(this.user);
                return this.user;
            })
        );
    }

    /*
     * These 2 methods are useful to manage locales even when user is not connected
     */
    setLocales(lang:string, country?:string): void {
        this.userLang = lang.toLowerCase();
        // if not provided, country is deduced from the language: fr -> FR, en -> EN
        this.userCountry = country ? country.toUpperCase() : lang.toUpperCase();
    }
    getLocales(): string {
        return this.userCountry ? `${this.userLang}-${this.userCountry}` : this.userLang;
    }

    updateGUILocales() {
        this.setLocales(this.user.language, this.user.countryCode);
        this.translator.use(this.userLang);
    }

}
