/*
 * Deeplinks of the App
 *
 * NOTICE: relative paths depend on routing  organization
 */
import { ACL } from '@src/app/_core/defs/_acl.defs';

export const PATHS = { //: {[pathKey:string]: Path}
    public_default:         {full: '/signin', relative: 'signin', acl: null},
    login:                  {full: '/signin', relative: 'signin', acl: null},
    lostpassword:           {full: '/lostpwd', relative: 'lostpwd', acl: null},
    lostpassword_init:      {full: '/lostpwd/init', relative: 'init', acl: null},
    lostpassword_final:     {full: '/lostpwd/final', relative: 'final', acl: null},

    logout:                 {full: '/private/logout', relative: 'logout', acl: null},
    blank:                  {full: '/private/blank', relative: 'blank', acl: null},
    forbidden:              {full: '/private/forbidden', relative: 'forbidden', acl: null},

    private:                {full: '/private', relative: 'private', acl: ACL.home },
    private_default:        {full: '/private/home', relative: 'home', acl: ACL.home },
    home:                   {full: '/private/home', relative: 'home', acl: ACL.home },

    processing:             {full: '/private/processing', relative: 'processing', acl: ACL.processing_see},
    processing_selector:    {full: '/private/processing/selector', relative: 'selector', acl: ACL.processing_manage},
    processing_list:        {full: '/private/processing/list', relative: 'list', acl: ACL.processing_see},
    processing_create:      {full: '/private/processing/create', relative: 'create', acl: ACL.processing_manage},
    processing_edit:        {full: '/private/processing/edit/:id', relative: 'edit/:id', acl: ACL.processing_edit},

    pia:                    {full: '/private/pia', relative: 'pia', acl: ACL.pia_see},
    pia_list:               {full: '/private/pia/list', relative: 'list', acl: ACL.pia_see},
    pia_create:             {full: '/private/pia/create', relative: 'create', acl: ACL.pia_manage},
    pia_edit:               {full: '/private/pia/edit/:id', relative: 'edit/:id', acl: ACL.pia_edit},
    pia_preview:            {full: '/private/pia/preview/:id', relative: 'preview/:id', acl: ACL.pia_see},
    // pia_archive:            {full: '/private/pia/archive', relative: 'archive', acl: ACL.pia},
    // pia_archive_list:       {full: '/private/pia/archive/list', relative: 'list', acl: ACL.pia},
    pia_entry:              {full: '/private/pia/entry/:id', relative: 'entry/:id', acl: ACL.pia_edit},
    pia_entry_item:         {full: '/private/pia/entry/:id/section/:sectionId/item/:itemId', relative: 'entry/:id/section/:sectionId/item/:itemId', acl: ACL.pia_edit},

    incident:               {full: '/private/incident', relative: 'incident', acl: ACL.incident_see},
    incident_list:          {full: '/private/incident/list', relative: 'list', acl: ACL.incident_see},
    incident_create:        {full: '/private/incident/create', relative: 'create', acl: ACL.incident_manage},
    incident_edit:          {full: '/private/incident/edit/:id', relative: 'edit/:id', acl: ACL.incident_edit},

    personrequest:          {full: '/private/request', relative: 'request', acl: ACL.personrequest_see},
    personrequest_list:     {full: '/private/request/list', relative: 'list', acl: ACL.personrequest_see},
    personrequest_create:   {full: '/private/request/create', relative: 'create', acl: ACL.personrequest_manage},
    personrequest_edit:     {full: '/private/request/edit/:id', relative: 'edit/:id', acl: ACL.personrequest_edit},

    subcontracting:                    {full: '/private/sub', relative: 'sub', acl: ACL.subcontracting_see},
    subcontracting_provider_list:      {full: '/private/sub/provider/list', relative: 'provider/list', acl: ACL.subcontracting_see},
    subcontracting_provider_create:    {full: '/private/sub/provider/create', relative: 'provider/create', acl: ACL.subcontracting_manage},
    subcontracting_provider_edit:      {full: '/private/sub/provider/edit/:id', relative: 'provider/edit/:id', acl: ACL.subcontracting_edit},

    guide:                      {full: '/private/guide', relative: 'guide', acl: ACL.guide_see},
    guide_list:                 {full: '/private/guide/list', relative: 'list', acl: ACL.guide_see},
    guide_create:               {full: '/private/guide/create', relative: 'create', acl: ACL.guide_manage},
    guide_edit:                 {full: '/private/guide/edit/:id', relative: 'edit/:id', acl: ACL.guide_manage},
    // 1: We can't use /private/guide/... as basis for shared guides routes else Angular will (logically) light up both entries of the menu
    // 2: Moreover, same remark as for processing-tpl: the relative routes shall be unique amongst guide routes + shared guide routes
    shared_guide:               {full: '/private/shared-guide', relative: 'shared-guide', acl: ACL.shared_guide_see},
    shared_guide_list:          {full: '/private/shared-guide/shared-list', relative: 'shared-list', acl: ACL.shared_guide_see},
    shared_guide_create:        {full: '/private/shared-guide/shared-create', relative: 'shared-create', acl: ACL.shared_guide_manage},
    shared_guide_edit:          {full: '/private/shared-guide/shared-edit/:id', relative: 'shared-edit/:id', acl: ACL.shared_guide_manage},

    //client_admin:               {full: '/private/cli-admin', relative: 'cli-admin', acl: ACL.client_admin},
    client_admin_user:          {full: '/private/cli-admin/user', relative: 'cli-admin/user', acl: ACL.client_admin_user},
    client_admin_user_list:     {full: '/private/cli-admin/user/list', relative: 'cli-admin/list', acl: ACL.client_admin_user},
    client_admin_user_create:   {full: '/private/cli-admin/user/create', relative: 'cli-admin/create', acl: ACL.client_admin_user},
    client_admin_user_edit:     {full: '/private/cli-admin/user/edit/:id', relative: 'cli-admin/edit/:id', acl: ACL.client_admin_user},

    admin:                      {full: '/private/admin', relative: 'admin', acl: ACL.admin},
    admin_home:                 {full: '/private/admin/home', relative: 'home', acl: ACL.admin},

    admin_organization:         {full: '/private/admin/organization', relative: 'organization', acl: ACL.admin_organization},
    admin_organization_list:    {full: '/private/admin/organization/list', relative: 'list', acl: ACL.admin_organization},
    admin_organization_create:  {full: '/private/admin/organization/create', relative: 'create', acl: ACL.admin_organization},
    admin_organization_edit:    {full: '/private/admin/organization/edit/:id', relative: 'edit/:id', acl: ACL.admin_organization},

    admin_user:                 {full: '/private/admin/user', relative: 'user', acl: ACL.admin_user},
    admin_user_list:            {full: '/private/admin/user/list', relative: 'list', acl: ACL.admin_user},
    admin_user_create:          {full: '/private/admin/user/create', relative: 'create', acl: ACL.admin_user},
    admin_user_edit:            {full: '/private/admin/user/edit/:id', relative: 'edit/:id', acl: ACL.admin_user},

    admin_processing_tpl:        {full: '/private/admin/processing-tpl', relative: 'processing-tpl', acl: ACL.admin_processing_tpl},
    // Warning: it's a bit tricky here : these routes are handled by the ProcessingRoutingModule
    //   => the relative route shall be unique amongst processing routes and processing-tpl routes
    admin_processing_tpl_list:   {full: '/private/admin/processing-tpl/tpl-list', relative: 'tpl-list', acl: ACL.admin_processing_tpl},
    admin_processing_tpl_create: {full: '/private/admin/processing-tpl/tpl-create', relative: 'tpl-create', acl: ACL.admin_processing_tpl},
    admin_processing_tpl_edit:   {full: '/private/admin/processing-tpl/tpl-edit/:id', relative: 'tpl-edit/:id', acl: ACL.admin_processing_tpl},

    admin_structure:            {full: '/private/admin/structure', relative: 'structure', acl: ACL.admin_structure},
    admin_structure_list:       {full: '/private/admin/structure/list', relative: 'list', acl: ACL.admin_structure},
    admin_structure_entry:      {full: '/private/admin/structure/entry/:id', relative: 'entry/:id', acl: ACL.admin_structure},
    admin_structure_entry_item: {full: '/private/admin/structure/entry/:id/section/:sectionId/item/:itemId', relative: 'entry/:id/section/:sectionId/item/:itemId', acl: ACL.admin_structure},


};
