/*
 * Interceptor service : add token in request header
 * Author EFWAY - F. Delaunay
 */

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as env } from '@src/environments/environment';
import { AuthenticationService } from '@src/app/_core/services/authentication.service';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthenticationService,
     ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authService.getToken()) {
            //// Request occurs in authenticated context : clone the request to add the adhoc header
            // Token is provided using the default Lexik JWT mode
            const clonedReq = req.clone({headers: req.headers.set('Authorization', 'Bearer ' + this.authService.getToken()) });  // .set('Content-type', 'application/json') n'est plus nécessaire avec angular 10 (et depuis ???)
            return next.handle(clonedReq);
        }
        else {
            //env.debug && console.log('auth header interceptor : no token -> do nothing');
            return next.handle(req);
        }
    }
}
