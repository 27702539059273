// ACL
import { AccessControl } from '@src/app/_core/interfaces/app.interfaces';
import { ProfileEnum as P } from '@src/app/_core/model/user.model';

// The ACL structure defines access RESTRICTIONS for any (routed?) functionality defined here by an arbitrary key
// Example of possible keys: 'pia', 'pia_see', 'pia_edit'
//
// Restrictions are set for profile :
//  null or [] means: "no restriction"
//  NOTICE: we keep compatible with a more sophisticated frame for more sophisticated ACL specifications in the future
//          -> seems not optimized right now but it does not really matter
// Interpretation is made by ACLService methods.
export const ACL = { //: {[aclKey:string]: AccessControl[]} = {
    home: null,
    processing_see: null,
    processing_manage: [
        {profile: P.ADMIN},
        {profile: P.ACCOUNTANT},
        {profile: P.CLIENT_ADMIN},
        // not CLIENT_USER
    ],
    processing_edit:[
        {profile: P.ADMIN},
        {profile: P.ACCOUNTANT},
        {profile: P.CLIENT_ADMIN},
        {profile: P.CLIENT_USER},
    ],
    subcontracting_see: null,
    subcontracting_manage: [
        {profile: P.ADMIN},
        {profile: P.ACCOUNTANT},
        {profile: P.CLIENT_ADMIN},
        {profile: P.CLIENT_USER}, // yes CLIENT_USER can create / delete a provider &contract
    ],
    subcontracting_edit:[
        {profile: P.ADMIN},
        {profile: P.ACCOUNTANT},
        {profile: P.CLIENT_ADMIN},
        {profile: P.CLIENT_USER},
    ],
    pia_see: null,
    pia_manage: [
        {profile: P.ADMIN},
        {profile: P.ACCOUNTANT},
        {profile: P.CLIENT_ADMIN},
        // not CLIENT_USER
    ],
    pia_edit:[
        {profile: P.ADMIN},
        {profile: P.ACCOUNTANT},
        {profile: P.CLIENT_ADMIN},
        {profile: P.CLIENT_USER},
    ],
    incident_see: null,
    incident_manage: [
        {profile: P.ADMIN},
        {profile: P.ACCOUNTANT},
        {profile: P.CLIENT_ADMIN},
        {profile: P.CLIENT_USER}, // yes CLIENT_USER can create / delete an incident
    ],
    incident_edit:[
        {profile: P.ADMIN},
        {profile: P.ACCOUNTANT},
        {profile: P.CLIENT_ADMIN},
        {profile: P.CLIENT_USER},
    ],
    personrequest_see: null,
    personrequest_manage: [
        {profile: P.ADMIN},
        {profile: P.ACCOUNTANT},
        {profile: P.CLIENT_ADMIN},
        {profile: P.CLIENT_USER}, // yes CLIENT_USER can create / delete a request
    ],
    personrequest_edit:[
        {profile: P.ADMIN},
        {profile: P.ACCOUNTANT},
        {profile: P.CLIENT_ADMIN},
        {profile: P.CLIENT_USER},
    ],
    contact_see: null,
    contact_manage: [
        {profile: P.ADMIN},
        {profile: P.ACCOUNTANT},
        {profile: P.CLIENT_ADMIN},
        {profile: P.CLIENT_USER}, // yes CLIENT_USER can create / delete a contact
    ],
    contact_edit:[
        {profile: P.ADMIN},
        {profile: P.ACCOUNTANT},
        {profile: P.CLIENT_ADMIN},
        {profile: P.CLIENT_USER},
    ],
    location_see: null,
    location_manage: [
        {profile: P.ADMIN},
        {profile: P.ACCOUNTANT},
        {profile: P.CLIENT_ADMIN},
    ],
    location_edit:[
        {profile: P.ADMIN},
        {profile: P.ACCOUNTANT},
        {profile: P.CLIENT_ADMIN},
    ],
    guides: null, // organization-specific guides as well as shared guides (due to factorization of the same GuideComponent)
    guide_see: null,
    guide_manage: [
        {profile: P.ADMIN},
        {profile: P.ACCOUNTANT},
        {profile: P.CLIENT_ADMIN},
    ],
    shared_guide_see: null,
    shared_guide_manage: [
        {profile: P.ADMIN},
    ],

    // client_admin: [
    //     {profile: P.CLIENT_ADMIN},
    // ],
    client_admin_user: [ // used to restrict menu entry visibility and
        {profile: P.CLIENT_ADMIN},
    ],
    admin: [
        {profile: P.ADMIN},
    ],
    admin_organization: [
        {profile: P.ADMIN},
    ],
    admin_user: [
        {profile: P.ADMIN},
        {profile: P.CLIENT_ADMIN},
    ],
    admin_processing_tpl: [
        {profile: P.ADMIN},
    ],
    admin_structure: [
        {profile: P.ADMIN},
    ]
};
