
<form [formGroup]="myForm" novalidate class="full-width" >
    <!---- names  ---->
    <div class="wid-form-2cols">
        <mat-form-field appearance="outline">
            <mat-label>{{(isCompany ? 'contact.field.companyName.label' : 'contact.field.name.label')|translate}}</mat-label>
            <input formControlName="name"
                   matInput type="text"
                   maxlength="{{ASSERTS.name.maxlength}}"
                   trim="blur"
                   required
                   [errorStateMatcher]="matcher"
                   placeholder="{{(isCompany ? 'contact.field.companyName.label' : 'contact.field.name.label')|translate}}">
            <mat-error *ngIf="myForm.get('name').hasError('required')">{{'common.field.err_required'|translate }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" [class.hidden]="isCompany">
            <mat-label>{{'contact.field.firstname.label'|translate}}</mat-label>
            <input formControlName="firstname"
                   matInput type="text"
                   maxlength="{{ASSERTS.firstname.maxlength}}"
                   trim="blur"
                   [errorStateMatcher]="matcher"
                   placeholder="{{'contact.field.firstname.label'|translate}}">
        </mat-form-field>
    </div>

    <!---- phone & email ---->
    <div class="wid-form-2cols">
        <mat-form-field appearance="outline">
            <mat-label>{{'contact.field.phone.label'|translate}}</mat-label>
            <input formControlName="phone"
                   matInput type="text"
                   maxlength="{{ASSERTS.phone.maxlength}}"
                   trim="blur"
                   [errorStateMatcher]="matcher"
                   placeholder="{{'contact.field.phone.label'|translate}}">
        </mat-form-field>

        <mat-form-field appearance="outline" >
            <mat-label>{{'contact.field.email.label'|translate}}</mat-label>
            <input formControlName="email"
                   matInput type="text"
                   maxlength="{{ASSERTS.email.maxlength}}"
                   trim="blur"
                   [errorStateMatcher]="matcher"
                   placeholder="{{'contact.field.email.label'|translate}}">
            <mat-error *ngIf="myForm.get('email').hasError('pattern')">{{'common.field.err_emailPattern'|translate}}</mat-error>
            <mat-error *ngIf="myForm.get('email').hasError('maxLength')">{{'common.field.err_maxLength'|translate:{max:ASSERTS.email.maxlength} }}</mat-error>
        </mat-form-field>
    </div>

    <!---- Address ---->
    <mat-form-field appearance="outline">
        <mat-label>{{'contact.field.address.label'|translate}}</mat-label>
        <input formControlName="address"
               matInput type="text"
               maxlength="{{ASSERTS.address.maxlength}}"
               trim="blur"
               [errorStateMatcher]="matcher"
               placeholder="{{'contact.field.address.label'|translate}}">
    </mat-form-field>

    <!---- zipcode & city & country ---->
    <div class="wid-form-3cols">
        <mat-form-field appearance="outline">
            <mat-label>{{'contact.field.zipcode.label'|translate}}</mat-label>
            <input formControlName="zipcode"
                   matInput type="text"
                   maxlength="{{ASSERTS.zipcode.maxlength}}"
                   trim="blur"
                   [errorStateMatcher]="matcher"
                   placeholder="{{'contact.field.zipcode.label'|translate}}">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'contact.field.city.label'|translate}}</mat-label>
            <input formControlName="city"
                   matInput type="text"
                   maxlength="{{ASSERTS.city.maxlength}}"
                   trim="blur"
                   [errorStateMatcher]="matcher"
                   placeholder="{{'contact.field.city.label'|translate}}">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{'contact.field.country.label'|translate}}</mat-label>
            <input formControlName="country"
                   matInput type="text"
                   maxlength="{{ASSERTS.country.maxlength}}"
                   trim="blur"
                   [errorStateMatcher]="matcher"
                   placeholder="{{'contact.field.country.label'|translate}}">
        </mat-form-field>
    </div>


</form>

