/**
 * QEOPS - WIDIP 2020-21
 * Created by EFWAY / F. Delaunay
 */

/*
 * Defines stuff exchanged with the back-end thru the REST API and that is NOT BUSINESS OBJECTS
 */

//== Resources (value = the endpoint discriminant)
export enum ItemTypeEnum {
    CONTACT = 'contacts',
    CONTRACT = 'contracts',
    DOCUMENT = 'documents',
    DOCUMENTATIONCENTER = 'documentationcenters', // this actually is a virtual "parent" resource for Qeops shared guide documents
    ORGANIZATION = 'organizations',
    LOCATION = 'locations',
    INCIDENT = 'incidents',
    USER = 'users',
    PERSONREQUEST = 'personrequests',
    PIA = 'pias',
    PROCESSING = 'processings',
    PROCESSINGTEMPLATE = 'processingtemplates',
    PROCESSINGCATEGORY = 'processingcategories',
    PROVIDER = 'providers',
    STRUCTURE = 'structures', // = pia template
}

//== The error messages that come as complement to http error code and that we need to recognize for a specific reaction
export const PASSWORD_CHANGE_MSG = 'auth.err_passwordToChange'; // the message we get  from backend with a 401 error if changing password is requested
export const PASSWORD_CHANGE_FIRSTTIME_MSG = 'auth.err_passwordToChangeFirstTime'; // the message we get  from backend with a 401 error if changing password is requested for the fitst time (first access to)

export const SESSION_EXPIRED_MSG = 'auth.err_expiredToken';     // the message we get from backend with a 401 error when the session expired
//export const SESSION_EXPIRED_MSG = 'EXPIRED JWT TOKEN'; // the message we get with a 401 error when the session expired

//================ Common or Generic  =========================

export interface BeUpdateResponse {
    id: number;
}

//================ Authentication & co =========================

export interface BeAuthenticateRequest {
    username: string;
    password: string;
}
export interface BeAuthenticateResponse {
    token: string;    // JWT session token to be added in header of next requests
    refresh_token: string;  // JWT refresh token
}

export interface BeRefreshTokenRequest {
    refresh_token: string;
}

export interface LostpwdInitRequest {
    username: string;
}

export interface LostpwdPasswordRequest {
    username: string;
    key: string;
    password: string;
}

//================ Other =========================
export interface BeDocumentFileRequest {
    filecontent: string;// | ArrayBuffer;
    name: string;
    title?: string;
    comment?: string;
}

export interface BeConfiguration {
    qeopsOrgId: number;
}
