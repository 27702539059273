/*
 * Author: EFWAY - F. Delaunay
 */

import { Injectable } from '@angular/core';

import { environment as env } from '@src/environments/environment';
import { AccessControl } from '@src/app/_core/interfaces/app.interfaces';
import { SessionUserService } from '@src/app/_core/services/session-user.service';
import { User, ProfileEnum } from '@src/app/_core/model/user.model';

@Injectable({
    providedIn: 'root'
})
export class AclService {

    constructor(private sessionService: SessionUserService){}

    /*
     * Checks whether ACCESS TO ACTION can be granted according user's profile and groups
     * "acl" is a key of CAN_DO
     */
    public canAccess(actionAcl: AccessControl[]): boolean {
        return AclService.userCanAccess(this.sessionService.user, actionAcl)
    }

    /*
     * This method remains public for a more performant, static access by callers which know "user" because they have injected the UserService
     */
    public static userCanAccess(user:User, actionAcl:AccessControl[]): boolean {
        // user not defined -> false
        // actionAcl empty means no restrictions -> true
        // else -> according user's profile & group
        return !actionAcl ? true
            : !user ? false
                : (actionAcl.length == 0 || this.profileAndGroupGranted(user, actionAcl) );
    }

    /*
     * Check whether the organization (of an ITEM) is in user's scope
     * USELESS - to be removed
     */
    public inScope(organizationId: number): boolean {
        return AclService.inUserScope(this.sessionService.user, organizationId);
    }
    public static inUserScope(user:User, organizationId: number): boolean {
        return user.isWidip || (user.organization.id == organizationId);
    }

    /*
     *
     */
    private static profileAndGroupGranted(user:User, actionAcl:AccessControl[] ):boolean {
        let isGranted: boolean = false;
        for (let accessControl of actionAcl) {
            if (!accessControl.profile || user.profile == accessControl.profile) { // || user.roles.includes(accessControl.profile)
                isGranted = true;
                //// More sophisticated acl is useless till now
                // if (accessControl.groups && accessControl.groups.length > 0) { //they are some restrictions for groups
                //     isGranted = false;
                //     // If one of the user's groups is granted, user is granted
                //     for (let group of accessControl.groups) {
                //         if (user.groups.some(id => id == group) ) {
                //             isGranted = true;
                //             break;
                //         }
                //     }
                // } else {
                //     isGranted = true;
                // }
            }
            // NOTICE: no break if isGranted = true because a more specialized AccessControl further in the list might override it
        }
        return isGranted;
    }
}
