/*
 * QEOPS - (c) WIDIP Software 2020
 * Author EFWAY - F. Delaunay
 */

export class Document {
    public id?: number;
    public name?: string;
    public mimetype?: string;
    public title?: string;
    public comment?: string;
    public category?: string;
    public position?: number; // order inside category
    public createdAt?: Date;
    public parentId?: number; // id of the item the document is attached to

    //==================  Virtual properties ====================//
    public get fileExtension(): string {
        return this.name?.indexOf(".") > -1
            ? this.name?.substring(this.name?.lastIndexOf(".") + 1)
            : null;
    }

    // get sortableFingerprint(): string { // for use by sorting in Guide pages
    //     return (`${this.category||''}`.padStart(100, ' ') + `${this.position||0}`.padStart(20, '0') + (this.title||this.name)).toLowerCase()
    // }

    //==================  Constructor ===========================//
    constructor(beDocument?: any) {
        if (beDocument) {
            Object.assign(this, beDocument);
            this.createdAt = beDocument.createdAt
                ? new Date(beDocument.createdAt)
                : null;
        }
    }

    //========== "reverse constructor" for requests to the backend =========//
    buildBeRequest(): any {
        const beDocument: any = {};
        return Object.assign(beDocument, this);
    }
}
