/*
 * Author EFWAY F. Delaunay
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpErrorResponse, HttpRequest, HttpEventType } from '@angular/common/http';
import { Observable, of as observableOf, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError, last } from 'rxjs/operators';

import { environment as env } from '@src/environments/environment';
import { BeDocumentFileRequest, ItemTypeEnum, BeUpdateResponse } from '@src/app/_core/interfaces/rest-api.interfaces';
import { PaginatedList, Sort } from '@src/app/_core/interfaces/app.interfaces';
import { RestApiUtilities as BA } from '@src/app/_core/utils/rest-api.utilities';
import { Document } from '@src/app/_core/model/document.model';
import { ErrorService } from '@src/app/_core/services/error.service';
import { ConfigurationService } from '@src/app/_core/services/configuration.service';

/*
 * Document service :
 *    Handle any kind of documents : parentType & parentId are type & id of the PARENT item ; they are used to build the routes
 *    Also handle "Guide" documents which are NOT attached to any parent item => the case is specifically handled when building routes
 *    NO worth trying to also handle CNIL's Attachments because they have to many specificities (see attachmentService for Pia attachments)
 *    DO NOT use generic methods of RestApiService : too many slight differences
 */
@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    private uploadProgressBS:BehaviorSubject<number> = new BehaviorSubject(null); // upload progress - internal
    uploadProgress$ = this.uploadProgressBS.asObservable(); // the publicly exposed observable

    constructor(
        private http: HttpClient,
        private errorService: ErrorService,
        private configService: ConfigurationService
    ) {}

    /* ============================================================================================================== */
    /* Public METHODS
    /* ============================================================================================================== */

    getDocumentList(parentType: ItemTypeEnum, parentId: number, sort?: Sort): Observable<PaginatedList> {
        return this.beSearchDocuments(parentType, parentId, sort).pipe(
            map((response: PaginatedList) => {
                return {
                    total: response.total,
                    items: (response.items||[]).map(beDoc =>  new Document(beDoc))
                }
            })
        );
    }

    getDocument(parentType: ItemTypeEnum, parentId: number, documentId: number): Observable<Document> {
        return this.beGetDocument(parentType, parentId, documentId).pipe(
            map((response) => new Document(response))
        );
    }

    /*
     * Create document, uploading its file
     */
    createDocument(parentType: ItemTypeEnum, parentId: number, fileRequest: BeDocumentFileRequest): Observable<Document> {
        if (fileRequest) {
            return this.beCreateDocument(parentType, parentId, fileRequest).pipe(
                map((beDoc) => new Document(beDoc))
            );
        } else {
            return observableOf(null);
        }
    }

    /*
     * Update some documents information but do not upload its file
     */
    updateDocument(parentType: ItemTypeEnum, parentId: number, document: Partial<Document>): Observable<number> {
        return this.bePatchDocument(parentType, parentId, document.buildBeRequest());
    }

    deleteDocument(parentType: ItemTypeEnum, parentId: number, documentId: number): Observable<any> {
        return this.beDeleteDocument(parentType, parentId, documentId);
    }

    downloadDocumentFile(parentType: ItemTypeEnum, parentId:number, documentId:number) {
        return this.beDownloadFile(parentType, parentId, documentId);
    }

    /* ============================================================================================================== */
    /*  BACKEND REST API */
    /* ============================================================================================================== */

    // DESIGN NOTICE :
    //     We want to reuse this service for guide documents, which are not attached to any parent item
    //      => their routes don't match the general pattern
    //      => need to specifically handle the case :-(
    //
    private itemRoute(parentType: ItemTypeEnum, parentId:number): string {
        //return (parentType == ItemTypeEnum.DOCUMENTATIONCENTER) ? `centerdocuments` : `${parentType}/${parentId}/documents`;
        const realParentType = (parentType == ItemTypeEnum.DOCUMENTATIONCENTER) ? ItemTypeEnum.ORGANIZATION : parentType;
        const realParentId = (parentType == ItemTypeEnum.DOCUMENTATIONCENTER && parentId === null) ? this.configService.configuration.qeopsOrgId : parentId;
        return `${realParentType}/${realParentId}/documents`
    }

    private beSearchDocuments(parentType: ItemTypeEnum, parentId: number, sort?: Sort): Observable<PaginatedList> { //itemType
        const url = `${env.beEndpoint}/${this.itemRoute(parentType, parentId)}`;
        let params = new HttpParams();
        params =  BA.addSort(params, sort);

        return this.http.get(url, {params: params, observe: 'response'}).pipe(
            map((response: HttpResponse<Document[]>) => {
                return <PaginatedList>{
                    total: BA.getTotalCountInListResponse(response),
                    items: response.body
                }
            }),
            catchError(error => {
                this.errorService.handleError(error);
                return throwError(error);
            })
        );
    }

    private beGetDocument(parentType: ItemTypeEnum, parentId: number, documentId:number): Observable<Document> {
        const url = `${env.beEndpoint}/${this.itemRoute(parentType, parentId)}/${documentId}`;
        return this.http.get<Document>(url).pipe(
            catchError(error => {
                this.errorService.handleError(error);
                return throwError(error);
            })
        );
    }

    private beCreateDocument(parentType: ItemTypeEnum, parentId: number, fileRequest: BeDocumentFileRequest): Observable<Document> {
        const url = `${env.beEndpoint}/${this.itemRoute(parentType, parentId)}`;
        const req = new HttpRequest('POST', url, fileRequest, {reportProgress: true});
        this.uploadProgressBS.next(0);

        return this.http.request(req).pipe(
            map((event) => {
                switch (event.type) {
                    case HttpEventType.Sent: {this.uploadProgressBS.next(0); break}  // upload started
                    case HttpEventType.UploadProgress: {this.uploadProgressBS.next(Math.round(100 * event.loaded / event.total)); break;}// percent done
                    case HttpEventType.Response: { // upload completed : return the caller expected response aka the doc
                        this.uploadProgressBS.next(100); // robustness only
                        return event.body as Document;
                    }
                    default: // other events are not pertinent in this context
                }
            }),
            last(), // return to caller on final event
            catchError(error => {
                this.errorService.handleError(error);
                return throwError(error);
            })
        );

        //return throwError(new HttpErrorResponse({status: 400, error: {message: 'erreur doc'} })); // testing
    }

    private bePatchDocument(parentType: ItemTypeEnum, parentId: number, beDocument: Partial<Document>): Observable<number> {
        const url = `${env.beEndpoint}/${this.itemRoute(parentType, parentId)}/${beDocument.id}`;
        return this.http.patch<BeUpdateResponse>(url, beDocument).pipe(
            map( (response:BeUpdateResponse) => {
                return response.id;
            }),
            catchError(error => {
                this.errorService.handleError(error);
                return throwError(error);
            })
        );
    }

    private beDeleteDocument(parentType: ItemTypeEnum, parentId: number, documentId:number) {
        const url = `${env.beEndpoint}/${this.itemRoute(parentType, parentId)}/${documentId}`;
        return this.http.delete(url).pipe(
            catchError(error => {
                this.errorService.handleError(error);
                return throwError(error);
            })
        );
    }

    private beDownloadFile(parentType: ItemTypeEnum, parentId: number, documentId:number){
        const url = `${env.beEndpoint}/${this.itemRoute(parentType, parentId)}/${documentId}/file`;
        return this.http.get(url, {responseType: 'blob'}).pipe(
            catchError(error => {
                this.errorService.handleError(error);
                return throwError(error);
            })
        );
    }

}

// === Fridge ===
/* Alternative
createDocument(...) {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
        const documentRequest:BeDocumentFileRequest = {
            name: file.name,
            mimetype: file.type,
            filecontent: reader.result.split(',')[1]
        };
        return this.beUploadDocument(itemType, itemId, documentRequest);
    }
    //etc ...
}
*/
