/*
 * Author EFWAY / F. Delaunay
 * Displays errors of a FormControl
 */

import { Component, OnInit, Input, SimpleChange, OnChanges} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FieldError} from './field-error.interface';

@Component({
    selector: 'fwy-field-error',
    templateUrl: './field-error.component.html',
    styleUrls: ['field-error.component.scss']
})
export class FieldErrorComponent implements OnInit, OnChanges {
    @Input() control: AbstractControl; // FormControl
    @Input() errors: Array<FieldError>;

    constructor( ) { }

    ngOnInit() {
    }

    ngOnChanges(changes: {[propKey: string]: SimpleChange}): void {

    }

    hasRuleRaised(control:AbstractControl, error:FieldError) {
        for(let rule of error.rules) {
            //console.log('control rule='+rule+JSON.stringify(control[rule]));
            if(control[rule]) return true;
        }
        return false;
    }

}
