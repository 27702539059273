/*
 * QEOPS - WIDIP Software 2020
 * Author EFWAY - F. Delaunay
 */

/*
export enum ContactTypeEnum {
    INTERNAL= 'internal',       // a contact associated to a user account or referred to by a Processing or a Pia
    REQUESTER = 'requester',     // a person that has emitted a PersonRequest
    //PROVIDER = 'provider',     // todo rework Provider in order it uses Contact
}
*/

export class Contact  {
    public id?: number;
    public isCompany?: boolean;
    public name?: string;
    public firstname?: string;
    public email?: string;
    public address?: string;
    public zipcode?: string;
    public city?: string;
    public country?: string;
    public phone?: string;
    public title?: string;
    public userId?: number;
    public organizationId?: number;

    constructor(beContact?: any) {
        if (beContact) {
            Object.assign(this, beContact);
        }
    }

    buildBeRequest(): any { // don't care about a precise type
        const beContact: any = {};
        return Object.assign(beContact, this);
    }

    public displayFullname(display: 'lastfirst'|'administrative'|'firstlast'|'natural'|'f.last'|'abbrev' = 'lastfirst'): string {
        switch (display) {
            case 'administrative':
            case 'lastfirst': {
                return `${this.name} ${this.firstname}`;
            }
            case 'natural':
            case 'firstlast': {
                return `${this.firstname} ${this.name}`;
            }
            case 'abbrev':
            case 'f.last': {
                if (this.firstname) {
                    return `${this.firstname.substring(0,1)}. ${this.name}`
                } else {
                    return this.name
                }
            }
            default: {
                return `${this.name} ${this.firstname}`;
            }
        }
    }
}
