// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    name: '',
    instanceName: '',
    version: '0.17.0',    // Version - not the most appropriate file but this avoids handling another .tpl for substitutions
    log: true,                     // "false" as a general rule
    debug: false,                 //"false" as a general rule
    beUrl: 'https://backoffice.qeops.fr',              // url of the backend (used for instance to get some images)
    piaUrl: 'https://aipd.qeops.fr',            // url of the external PIA app
    beEndpoint: 'https://backoffice.qeops.fr/api',    // endpoint of the rest api
    supportEmail: 'Support QEOPS <sav@qeops.fr>',
};
