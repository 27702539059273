/*
 * Author: EFWAY - F. Delaunay
 */

import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

import { CSS } from '@src/app/_core/defs/app.defs';

export interface Viewport {
    isXSmall: boolean,          // < (576) -> 600
    isSmallDown: boolean,       // < 768
    isMediumDown: boolean,      // < 992
    isLargeDown: boolean,       // < 1200
    isSmallUp: boolean,         // >= (576) -> 600
    isMediumUp: boolean,        // >= 768
    isLargeUp: boolean,         // >= 992
    isXLargeUp: boolean,        // >= 1200
    isXXLarge: boolean          // >= 1680
}

@Injectable({
    providedIn: 'root'
})
export class ViewportService {

    public viewport: Viewport = {
        isXSmall: false,
        isSmallDown: false,
        isMediumDown: false,
        isLargeDown: false,
        isSmallUp: false,
        isMediumUp: false,
        isLargeUp: false,
        isXLargeUp: false,
        isXXLarge: false,
    };

    constructor(breakpointObserver: BreakpointObserver){
        this.viewport.isXSmall = breakpointObserver.isMatched(CSS.xsmallAndDown);
        this.viewport.isSmallDown = breakpointObserver.isMatched(CSS.smallAndDown);
        this.viewport.isMediumDown = breakpointObserver.isMatched(CSS.mediumAndDown);
        this.viewport.isLargeDown = breakpointObserver.isMatched(CSS.largeAndDown);
        this.viewport.isSmallUp = breakpointObserver.isMatched(CSS.smallAndUp);
        this.viewport.isMediumUp = breakpointObserver.isMatched(CSS.mediumAndUp);
        this.viewport.isLargeUp = breakpointObserver.isMatched(CSS.largeAndUp);
        this.viewport.isXLargeUp = breakpointObserver.isMatched(CSS.xlargeAndUp);
        this.viewport.isXXLarge = breakpointObserver.isMatched(CSS.xxlargeAndUp);
        breakpointObserver.observe([CSS.xsmallAndDown]).subscribe(result => { this.viewport.isXSmall = result.matches; });
        breakpointObserver.observe([CSS.smallAndDown]).subscribe(result => { this.viewport.isSmallDown = result.matches; });
        breakpointObserver.observe([CSS.mediumAndDown]).subscribe(result => { this.viewport.isMediumDown = result.matches; });
        breakpointObserver.observe([CSS.largeAndDown]).subscribe(result => { this.viewport.isLargeDown = result.matches; });
        breakpointObserver.observe([CSS.smallAndUp]).subscribe(result => { this.viewport.isSmallUp = result.matches; });
        breakpointObserver.observe([CSS.mediumAndUp]).subscribe(result => { this.viewport.isMediumUp = result.matches; });
        breakpointObserver.observe([CSS.largeAndUp]).subscribe(result => { this.viewport.isLargeUp = result.matches; });
        breakpointObserver.observe([CSS.xlargeAndUp]).subscribe(result => { this.viewport.isXLargeUp = result.matches; });
        breakpointObserver.observe([CSS.xxlargeAndUp]).subscribe(result => { this.viewport.isXXLarge = result.matches; });
    }
}
