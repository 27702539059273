<!-- <div> -->
    <div class="wid-closable-container padded">
        <span class="wid-action wid-close" (click)="onCancel()" >
            <mat-icon>close</mat-icon>
        </span>

        <mat-card-title class="wid-closable-container-header">
            <ng-container>
                {{(data.action == actionEnum.UPDATE ? 'location.edit.titleUpdate' : 'location.edit.titleCreate')|translate}}
                <span *ngIf="data.action == actionEnum.UPDATE">[{{data.item.name}}]</span>
            </ng-container>
        </mat-card-title>

        <form [formGroup]="myForm" novalidate class="full-width" >
            <!---- name  ---->

            <mat-form-field appearance="outline">
                <mat-label>{{'location.field.name.label'|translate}}</mat-label>
                <input formControlName="name"
                       matInput type="text"
                       maxlength="{{ASSERTS.name.maxlength}}"
                       trim="blur"
                       required
                       [errorStateMatcher]="matcher"
                       placeholder="{{'location.field.name.label'|translate}}">
            </mat-form-field>

            <!---- Address ---->
            <mat-form-field appearance="outline">
                <mat-label>{{'location.field.address.label'|translate}}</mat-label>
                <input formControlName="address"
                       matInput type="text"
                       maxlength="{{ASSERTS.address.maxlength}}"
                       trim="blur"
                       [errorStateMatcher]="matcher"
                       placeholder="{{'location.field.address.label'|translate}}">
            </mat-form-field>

            <!---- zipcode & city & country ---->
            <div class="wid-form-3cols">
                <mat-form-field appearance="outline">
                    <mat-label>{{'location.field.zipcode.label'|translate}}</mat-label>
                    <input formControlName="zipcode"
                           matInput type="text"
                           maxlength="{{ASSERTS.zipcode.maxlength}}"
                           trim="blur"
                           [errorStateMatcher]="matcher"
                           placeholder="{{'location.field.zipcode.label'|translate}}">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{'location.field.city.label'|translate}}</mat-label>
                    <input formControlName="city"
                           matInput type="text"
                           maxlength="{{ASSERTS.city.maxlength}}"
                           trim="blur"
                           [errorStateMatcher]="matcher"
                           placeholder="{{'location.field.city.label'|translate}}">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{'location.field.country.label'|translate}}</mat-label>
                    <input formControlName="country"
                           matInput type="text"
                           maxlength="{{ASSERTS.country.maxlength}}"
                           trim="blur"
                           [errorStateMatcher]="matcher"
                           placeholder="{{'location.field.country.label'|translate}}">
                </mat-form-field>
            </div>
        </form>

        <!-- Actions button -->
        <div class="wid-form-actions">
            <button mat-raised-button color="accent" type="submit"
                    class="fwy-spinner-container"
                    [class.submitting]="submitting"
                    [disabled]="myForm.invalid"
                    (click)="onSubmit()">
                <fwy-spinner [show]="submitting" type="uploader" [xPosition]="'left'" [xPositionAdjust]="'10px'"></fwy-spinner>
                {{(data.action == actionEnum.UPDATE ? 'location.edit.action.update' : 'location.edit.action.create')|translate}}
            </button>
            <a mat-button (click)="onCancel()">
                {{'location.edit.action.cancel'|translate}}
            </a>
        </div>
</div>
