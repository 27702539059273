/*
 * QEOPS (c) Widip 2020
 * Author: EFWAY - F. Delaunay
 */

import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { environment as env } from '@src/environments/environment';
import { ASSERTS } from '@src/app/_core/defs/app.defs';
import { MyErrorStateMatcher} from '@src/app/_core/utils/error-state-matcher';
import { Contact } from '@src/app/_core/model/contact.model';
import { MetaEditionFeedback } from '@src/app/_core/interfaces/app.interfaces';
import { Subscription } from 'rxjs';

interface CanSetProperty {
    name: boolean;
    firstname: boolean;
    address: boolean;
    zipcode: boolean;
    city: boolean;
    country: boolean;
    phone: boolean;
    email: boolean;
    //title: boolean;
}

/*
 * Common form as SUB-component that  manages the Contact form and emits a Contact object at any change
 * Limitation: CanSetProperty is not customizable by the parent, nor mandatory fields
 */
@Component({
    selector: 'wid-contact-form',
    templateUrl: './contact-form.component.html'
})
export class ContactFormComponent implements OnInit, OnDestroy {
    @Input() contact: Contact = null;
    @Input() canSetProperties: boolean = true; // true -> all properties editable, if false, all properties readonly - si un jour utile, on devrait plutôt passer un CanSetProperty pour plus de précision
    @Input() isCompany: boolean = false; // actually means "shall be a company"

    @Output() contactChanged = new EventEmitter<MetaEditionFeedback<Contact>>();

    myForm: FormGroup;
    matcher = new MyErrorStateMatcher();
    canSetProperty: CanSetProperty;

    ASSERTS:any = ASSERTS;

    formSubscr: Subscription;

    constructor(
        private formBuilder: FormBuilder,
    ) {}

    ngOnInit(): void {
        this.canSetProperty = this.initCanSetProperty(this.contact);
        this.myForm = this.buildForm(this.contact, this.canSetProperty);

        this.formSubscr = this.myForm.valueChanges.subscribe(()=> {this.onChange()});
    }

    ngOnDestroy(): void {
        this.formSubscr && this.formSubscr.unsubscribe();
    }

    onChange(): void {
        const contact: Contact = this.contact ?? new Contact();
        ['name', 'firstname', 'address', 'zipcode', 'city', 'country', 'phone', 'email'].forEach( field => {
            contact[field] = (this.myForm.get(field).value || '').trim().substring(0, ASSERTS[field].maxlength); //!!WARNING Hazardous dependency!!
        });
        contact.isCompany = this.isCompany;
        this.contactChanged.emit({invalid: this.myForm.invalid, item: contact})
    }


    private initCanSetProperty(contact?: Contact): CanSetProperty {
        return {
            name: this.canSetProperties,
            firstname: this.canSetProperties,
            address: this.canSetProperties,
            zipcode: this.canSetProperties,
            city: this.canSetProperties,
            country: this.canSetProperties,
            phone: this.canSetProperties,
            email: this.canSetProperties,
        }
    }

    private buildForm(contact:Contact, canSetProperty: CanSetProperty): FormGroup {

        return this.formBuilder.group({
            name: [
                {value: contact ? contact.name : null, disabled: !canSetProperty.name},
                [Validators.required, Validators.maxLength(ASSERTS.name.maxlength)]
            ],
            firstname: [
                {value: contact ? contact.firstname : null, disabled: !canSetProperty.firstname},
                [Validators.maxLength(ASSERTS.firstname.maxlength)]
            ],
            address: [
                {value: contact ? contact.address : null, disabled: !canSetProperty.address},
                [Validators.maxLength(ASSERTS.address.maxlength)]
            ],
            zipcode: [
                {value: contact ? contact.zipcode : null, disabled: !canSetProperty.zipcode},
                [Validators.maxLength(ASSERTS.zipcode.maxlength)]
            ],
            city: [
                {value: contact ? contact?.city : null, disabled: !canSetProperty.city},
                [Validators.maxLength(ASSERTS.city.maxlength)]
            ],
            country: [
                {value: contact ? contact?.country : null, disabled: !canSetProperty.country},
                [Validators.maxLength(ASSERTS.country.maxlength)]
            ],
            phone: [
                {value: contact ? contact?.phone : null, disabled: !canSetProperty.phone},
                [Validators.maxLength(ASSERTS.phone.maxlength)]
            ],
            email: [
                {value: contact ? contact?.email : null, disabled: !canSetProperty.email},
                [Validators.pattern(ASSERTS.email.pattern), Validators.maxLength(ASSERTS.email.maxlength)]
            ],

        });
    }

}
