/**
 * QEOPS - WIDIP Software 2020
 * Author EFWAY - F. Delaunay
 */

import { RestApiUtilities as BA } from '@src/app/_core/utils/rest-api.utilities';
import { Contact } from '@src/app/_core/model/contact.model';

export class Organization {
    public id?: number;
    public name?: string;
    public address?: string;
    public zipcode?: string;
    public city?: string;
    public phone?: string;
    public isQeops?: boolean;
    public isActive?: boolean;
    public createdAt?: Date;
    public userCount?: number;
    public contactCount?: number;
    public processingCount?: number;
    public piaCount?: number;
    // contacts for the processings register:
    public registerResponsible?: Contact;
    public registerDpo?: Contact;
    public registerRepresentative?: Contact;

    constructor(beOrg?: any) {
        if (beOrg) {
            Object.assign(this, beOrg);
            // At this point dates / contacts are set as string / interfaces  ; convert them to Dates / Contact
            this.createdAt = beOrg.createdAt ? new Date(beOrg.createdAt) : null;
            this.registerResponsible = beOrg.registerResponsible ? new Contact(beOrg.registerResponsible) : null;
            this.registerRepresentative = beOrg.registerRepresentative ? new Contact(beOrg.registerRepresentative) : null;
            this.registerDpo = beOrg.registerDpo ? new Contact(beOrg.registerDpo) : null;
        }
    }

    buildBeRequest(): any {
        //== Clone the Object - partially is better but not so important here
        // const beReq: any = {};
        // Object.assign(beReq, this);
        const excludedProperties: string[] = ['isQeops', 'isActive', 'createdAt', 'userCount', 'contactCount', 'processingCount', 'piaCount'];
        const beReq: any = BA.assignWithExclusion(this, excludedProperties );

        //== Send id not contact objects
        //== we use here the "glpi way" alternative : same property name but not the same content
        //== todo homogenize the API: choose either gpli way alternative either the "rigorous" alternative ... for ALL

        if (this.hasOwnProperty('registerResponsible')) {
            beReq.registerResponsible = this.registerResponsible ? this.registerResponsible.id : null;
        }
        if (this.hasOwnProperty('registerDpo')) {
            beReq.registerDpo = this.registerDpo ? this.registerDpo.id : null;
        }
        if (this.hasOwnProperty('registerRepresentative')) {
            beReq.registerRepresentative = this.registerRepresentative ? this.registerRepresentative.id : null;
        }
        return beReq;
    }

}
