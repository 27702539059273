/*
 * Here are css key values we need to use from the ts code
 * DO NOT FORGET TO reSYNC values with scss changes
 */

export const CSS = {
    hHeaderMobile: 56, // w < 600px
    hHeaderMedium: 64, // w >= 600px
    wForm: 750, // default
    wFormSmall: 600,
    wList: 1200, //default
    wListSmall: 767,

    //=== Viewport thresholds
    largeScreenMax: 1199,
    xsmallAndDown: '(max-width: 599px)', //575px
    smallAndDown: '(max-width: 767px)',
    mediumAndDown: '(max-width: 991px)',
    largeAndDown: '(max-width: 1199px)',
    smallAndUp: '(min-width: 600px)', //576px
    mediumAndUp:'(min-width: 768px)',
    largeAndUp: '(min-width: 992px)',
    xlargeAndUp: '(min-width: 1200px)',
    xxlargeAndUp: '(min-width: 1680px)',
    //=== Colors
    colxxx: '#000',
}
