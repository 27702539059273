<!--
<span class="wid-action wid-close" (click)="onClose()">
        <mat-icon>close</mat-icon>
</span>
-->
<h2 mat-dialog-title *ngIf="data?.title">{{data.title | translate}}</h2>

<div mat-dialog-content >
    <p style="text-align: center;" [innerHTML]="data.content | translate"></p>
</div>
<div class="wid-form-actions dialog">
    <button mat-raised-button color="accent" type="submit" (click)="onClose()">
        {{ (data?.button || 'common.action.closeDialog.label')|translate}}
    </button>
</div>
<div *ngIf="data?.bottomContent" class="bottomText" [innerHTML]="data.bottomContent | translate"></div>
