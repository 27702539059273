/*
 * Utilities for Backend api
 * Author EFWAY - F. DELAUNAY
 */

import { HttpParams, HttpResponse } from '@angular/common/http';

import { Sort, Pagination } from '@src/app/_core/interfaces/app.interfaces';

export class RestApiUtilities {
    /*
    * Convert each non-null property of the filter to a query param with param key = object property
    */
    static addFilter(params: HttpParams, filter: any): HttpParams {
        for (const criteria in filter) {
            if (filter.hasOwnProperty(criteria) && typeof filter[criteria] !== 'undefined' && filter[criteria] !== null) {
                const criteriaValue = filter[criteria];
                if ( Array.isArray(criteriaValue)) {
                    // If criteria is a list of values, "serialize" them
                    this.addCollectionFilter(params, criteriaValue, criteria )
                } else if (typeof criteriaValue === "boolean"){
                    params = params.append(criteria, `${criteriaValue ? 1 : 0}`);
                } else {
                    params = params.append(criteria, `${criteriaValue}`);
                }

            }
        }
        return params;
    }

    static addSort(params:HttpParams, sort:Sort): HttpParams {
        if (sort?.column) {
            params = params.append('sort', sort.column);
        }
        if (sort?.direction) {
            params = params.append('direction', sort.direction.toLowerCase());
        }
        return params;
    }

    /*
    * Transforms pagination in Backend criteria. Pages are counted from 1 to n by the App
    */
    static addPagination(params:HttpParams, pagination:Pagination): HttpParams {
        if(pagination) {
            if (pagination.currentPage != null && pagination.currentPage >= 1) { // both tests are pure robustness
                const offset = (pagination.currentPage - 1) * pagination.maxPerPage;
                params = params
                    .append('offset', `${offset}`)
                    .append('limit', `${pagination.maxPerPage}`)
                ;
            }
        }
        return params;
    }

    /*
     * result: key[]=value1&key[]=value2, ...
     */
    static addCollectionFilter(params:HttpParams, valueList:any[], key:string): HttpParams {
        if(!!valueList) {
            valueList.forEach(value => params = params.append(`${key}[]`,`${value}`));
        }
        return params;
    }

    /*
     *
     */
    static getTotalCountInListResponse(response: HttpResponse<any>): number {
        return +response.headers.get('X-Total-Count')
    }

    /*
     * Assign properties of a source object to a dest. object, excluding those that are specified.
     * This method is placed here because it is used to build api request objects,
     * but should be moved because it is actually not an "api-specific" method
     * Return a dest object which properties have been assigned from the source object, except those of the exclusion list
     */

    static assignWithExclusion(source: any, excludedProperties: string[] = []): any {
        const dest:any = {};
        for (const prop in source) {
            if (!excludedProperties.includes(prop)) dest[prop] = source[prop];
        }
        return dest;
    }

}
