/*
 * LIST utilities
 * Author EFWAY / F. DELAUNAY
 */

import { ActionEnum } from '@src/app/_core/interfaces/app.interfaces';

export class ListUtilities {


    /*
     * Update the provided list by adding, or remove the provided item from it, or by updating item
     * Assumes item has an 'id'
     * When Adding an item (action=CREATE), item is added at the end of the list (default) or at the beginning if option 'reversed' is true
     */
    static updateList(list: any[], item: any, action: ActionEnum, opt?:{reversed?: boolean} ): void {
        switch(action) {
            case ActionEnum.UPDATE: {
                const idx = list.findIndex( e => e.id ==item.id);
                if(idx != -1) {
                    list[idx] = item;
                }
                break;
            }
            case ActionEnum.DELETE: {
                const idx = list.findIndex( e => e.id == item.id);
                if(idx != -1) {
                    list.splice(idx, 1)
                }
                break;
            }
            case ActionEnum.CREATE: {
                opt?.reversed ? list.unshift(item) : list.push(item); // if reversed add item at the beginning of the list
                break;
            }
        }
    }
}
