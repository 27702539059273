/*
 * QEOPS - WIDIP Software 2020-2021
 * Author EFWAY / F. Delaunay
 */

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment as env } from '@src/environments/environment';
import { Organization } from '@src/app/_core/model/organization.model';
import { OrganizationFilter } from '@src/app/organization/_filter/organization-filter.interface';
import { Sort, Pagination, PaginatedList } from '@src/app/_core/interfaces/app.interfaces';
import { RestApiService, ResourceQualifier } from '@src/app/_core/services/rest-api.service';
import { ItemTypeEnum } from '@src/app/_core/interfaces/rest-api.interfaces';

@Injectable({
    providedIn: 'root',
})
export class OrganizationService {

    private resource: ResourceQualifier = {
        itemType: ItemTypeEnum.ORGANIZATION
    };

    constructor(
        private restApiService: RestApiService,
    ) {}

    /* ============================================================================================================== */
    /* Public METHODS DATA
    /* ============================================================================================================== */

    getOrganizationList(filter?: OrganizationFilter, sort?:Sort, pagination?:Pagination): Observable<PaginatedList> {
        return this.restApiService.searchItems<Organization>(this.resource, filter, sort, pagination).pipe(
            map((response: PaginatedList) => {
                return <PaginatedList>{
                    total: response.total,
                    items: (response.items||[]).map(item => new Organization(item))
                } })
        );
    }

    getOrganization(id: number): Observable<Organization> {
        return this.restApiService.getItem<Organization>({...this.resource, itemId: id}).pipe(
            map((response) => new Organization(response))
        );
    }

    createOrganization(item: Organization): Observable<Organization> {
        return this.restApiService.postItem<Organization>(this.resource, item.buildBeRequest()).pipe(
            map((response) => new Organization(response))
        );
    }

    updateOrganization(item: Partial<Organization>): Observable<number> {
        return this.restApiService.putItem<Organization>({...this.resource, itemId: item.id}, item.buildBeRequest());
    }

    updateOrganizationRegisterContacts(item: Partial<Organization>): Observable<number> {
        const resource = {
            itemType: ItemTypeEnum.ORGANIZATION,
            itemId: item.id,
            specific: 'registercontacts'
        }
        return this.restApiService.patchItem<Organization>(resource, item.buildBeRequest());
    }

    deleteOrganization(id: number): Observable<any> {
        return this.restApiService.deleteItem({...this.resource, itemId: id});
    }

}
