/*
 * QEOPS (c) Widip 2020
 * Author: EFWAY - F. Delaunay
 */

import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { environment as env } from '@src/environments/environment';
import { ASSERTS } from '@src/app/_core/defs/_asserts.defs';
import { MyErrorStateMatcher } from '@src/app/_core/utils/error-state-matcher';
import { StringUtilities as SU } from '@src/app/_core/utils/string-utilities';
import { ActionEnum, ActionFeedback } from '@src/app/_core/interfaces/app.interfaces';
import { Location } from '@src/app/_core/model/location.model';
import { OrganizationSelectorService } from '@src/app/_core/services/organization-selector.service';
import { LocationService } from '@src/app/_shared/location/location.service';

interface CanSetProperty {
    name: boolean;
    address: boolean;
    zipcode: boolean;
    city: boolean;
    country: boolean;
}
/*
 * This componenthandles creation or update of a Location
 */
@Component({
    selector: 'wid-location-edit-dialog',
    templateUrl: './location-edit-dialog.component.html'
})
export class LocationEditDialogComponent implements OnInit, OnDestroy {

    myForm: FormGroup;
    matcher = new MyErrorStateMatcher();
    canSetProperty: CanSetProperty; // for homogeneity we use this standard patterns although it is artificial here
    actionEnum = ActionEnum;
    ASSERTS = ASSERTS;

    submitting: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        private orgSelectorService: OrganizationSelectorService,
        private locationService: LocationService,
        public dialogRef: MatDialogRef<LocationEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ActionFeedback<Location>
    ) {}

    ngOnInit(): void {
        this.canSetProperty = this.initCanSetProperty(this.data.item);
        this.myForm = this.buildForm(this.data.item, this.canSetProperty);
    }

    ngOnDestroy(): void {
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        const location: Location = (this.data.action == ActionEnum.UPDATE) ? this.data.item : new Location();
        ['name', 'address', 'zipcode', 'city', 'country'].forEach( field => {
            location[field] = SU.trimAndStrip(this.myForm.get(field).value, ASSERTS[field].maxlength);
        });

        this.submitting = true;
        if (this.data.action == ActionEnum.UPDATE) {
            this.locationService.updateLocation(location).subscribe(
                (ok) => {
                    this.submitting = false;
                    this.dialogRef.close( <ActionFeedback<Location>>{
                        action: ActionEnum.UPDATE,
                        item: location
                    });
                    },
                (error) => {
                    this.submitting = false;
                }
            )
        } else {    // CREATION context
            const organization = this.orgSelectorService.getCurrentOrganization();
            if (organization) { // tested by robustness
                this.locationService.createLocation(organization.id, location).subscribe(
                    (newLocation) => {
                        this.submitting = false;
                        this.dialogRef.close( <ActionFeedback<Location>>{
                            action: ActionEnum.CREATE,
                            item: newLocation
                        });
                    },
                    (error) => {
                        this.submitting = false;
                    }
                );
            }
        }
    }

    private initCanSetProperty(location: Location): CanSetProperty {
        return {
            name: true,
            address: true,
            zipcode: true,
            city: true,
            country: true
        }
    }

    private buildForm(location: Location, canSetProperty: CanSetProperty): FormGroup {

        return this.formBuilder.group({
            name: [
                {value: location?.name, disabled: !canSetProperty.name},
                [Validators.required, Validators.maxLength(ASSERTS.name.maxlength)]
            ],
            address: [
                {value: location?.address, disabled: !canSetProperty.address},
                [Validators.maxLength(ASSERTS.address.maxlength)]
            ],
            zipcode: [
                {value: location?.zipcode, disabled: !canSetProperty.zipcode},
                [Validators.maxLength(ASSERTS.zipcode.maxlength)]
            ],
            city: [
                {value: location?.city, disabled: !canSetProperty.city},
                [Validators.maxLength(ASSERTS.city.maxlength)]
            ],
            country: [
                {value: location?.country, disabled: !canSetProperty.country},
                [Validators.maxLength(ASSERTS.country.maxlength)]
            ],
        });
    }

}
