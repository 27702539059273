<div class="wid-public-container wid-login">
    <div class="wid-public-content">
        <header>
            <img src="assets/img/logo-qeops.png"
                 alt="{{'common.app_name'|translate}}"
                 title="{{'common.app_name'|translate}} {{env.instanceName}} {{env.version}}">
            <!--<div  class="wid-instance">{{env.instanceName}}</div>-->
        </header>

        <main>
            <mat-card class="mat-elevation-z2 wid-login-box">
                <mat-card-title>{{'login.title'|translate}}</mat-card-title>
                <hr class="wid-spacer hide-on-xxs-and-down"/>
                <form [formGroup]="myForm" class="full-width" novalidate >
                    <mat-form-field appearance="outline" hideRequiredMarker="true"><!--floatLabel="always"-->
                        <mat-label>{{'login.login.label'|translate}}</mat-label>
                        <input matInput type="email"
                               id="login" name="login"
                               formControlName="login"
                               [errorStateMatcher]="matcher"
                               required
                               maxlength="{{ASSERTS.email.maxlength}}"
                               trim="blur">
                    </mat-form-field>

                    <mat-form-field appearance="outline" hideRequiredMarker="true">
                        <mat-label floating>{{'login.password.label'|translate}}</mat-label>
                        <input matInput type="{{passwordVisible ? 'text' : 'password'}}"
                               id="password" name="password"
                               formControlName="password"
                               [errorStateMatcher]="matcher"
                               required
                               maxlength="{{ASSERTS.password.maxlength}}"
                               trim="blur">
                        <mat-icon class="wid-clickable wid-icon-color" matSuffix (click)="passwordVisible = !passwordVisible">{{passwordVisible ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>
                    <hr class="wid-spacer hide-on-xxs-and-down"/>
                    <button mat-raised-button color="accent" type="submit"
                            class="fwy-spinner-container"
                            [disabled]="myForm.invalid"
                            (click)="onSubmit()">
                        <fwy-spinner [show]="submitting" [xPosition]="'left'" [xPositionAdjust]="'10px'"></fwy-spinner>
                        {{'login.actions.submit'|translate}}
                    </button>
                </form>
                <div class="wid-tolostpwd">
                    <a class="wid-ctn" [routerLink]="[PATHS.lostpassword.full]">{{'login.links.lostpassword'|translate}}</a>
                </div>
            </mat-card>
        </main>

    <!--<footer>version {{env.version}}</footer>-->
    </div>


    <div class="wid-public-aside"></div>

</div>
