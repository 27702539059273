/*
 * QEOPS (c) WIDIP 2020-2024
 * Author EFWAY / F. Delaunay
 */

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { environment as env } from '@src/environments/environment';
import { PATHS, ASSERTS } from '@src/app/_core/defs/app.defs';
import { MyErrorStateMatcher } from '@src/app/_core/utils/error-state-matcher';
import { AuthenticationService } from '@src/app/_core/services/authentication.service';
import { ErrorService } from '@src/app/_core/services/error.service';
import { AlertService } from '@src/app/_core/services/alert.service';
import { OrganizationSelectorService } from '@src/app/_core/services/organization-selector.service';
import { LostpwdFinalRouteData } from '@src/app/lostpwd/lostpwd.interfaces';

@Component({
    templateUrl: './login.component.html',
})

export class LoginComponent implements OnInit {
    readonly env:any =  env;
    readonly PATHS:any = PATHS;
    readonly ASSERTS:any = ASSERTS;

    myForm: FormGroup;
    matcher = new MyErrorStateMatcher();
    submitting: boolean = false;
    passwordVisible: boolean = false;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private errorService: ErrorService,
                private alertService: AlertService,
                private authService: AuthenticationService,
                private orgSelectorService: OrganizationSelectorService,
                private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        // by robustness, should not be necessary: reset login status and unfreeze org. selector
        this.authService.clearAuthenticationInfos();
        this.orgSelectorService.unfreezeSelector();
        //
        this.myForm = this.createForm();
    }

    onSubmit(): void {
        const login = this.myForm.get('login').value.substring(0,ASSERTS.email.maxlength);
        const password = this.myForm.get('password').value.substring(0,ASSERTS.password.maxlength);
        this.submitting = true;
        this.authService.authenticate(login, password).subscribe(
            data => {
                this.submitting = false;
                // get the return url from route parameters if it exists, else go to default loggedin page
                const returnUrl = this.route.snapshot.queryParams['returnUrl'] || PATHS.private_default.full;
                this.router.navigate([returnUrl]);
            },
            error => {
                this.submitting = false;
                const forcePasswordData = this.errorService.getDataWhenForcePasswordError(error); // analysing error is delegated to the error service; it is role
                if (forcePasswordData) {
                    // Means that login/password was ok but that server requires we change the password
                    //  -> redirected to change password final page, skipping init page because we have got the resetPasswordKey in error response
                    this.router.navigate([PATHS.lostpassword_final.full, <LostpwdFinalRouteData>{
                        username : login,
                        key: forcePasswordData.key,
                        ctx: forcePasswordData.firstTime ? 'forcePasswordChangeFirstTime' : 'forcePasswordChange'
                    }]);
                } else {
                    this.errorService.handleError(error, {loginContext: true});
                }
            }
        );
    }

    private createForm(): FormGroup {
        return this.formBuilder.group({
            login: ['',
                [ Validators.required ] // Validators.pattern(ASSERTS.email.pattern) //, Validators.email ]
            ],
            password: ['',
                [ Validators.required] // Validators.pattern(ASSERTS.password.pattern) : no, too much feedback for hackers
            ]
        });
    }

}
