/*
 * FILE utilities
 * Author EFWAY - F. DELAUNAY
 */

const PICTURE_MIMETYPES:string[] = [ // accepted formats for pictures
    'image/jpeg',
    'image/png',
    'image/gif'
];

const ACCEPTED_MIMETYPES: string[] = [ // accepted formats for WIDIP file (picture or not)
    'application/pdf', 'text/plain',
    'image/jpeg', 'image/png', 'image/gif',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/msword', 'application/vnd.ms-excel', 'application/vnd.ms-powerpoint',
    'application/vnd.oasis.opendocument.text', 'application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.oasis.opendocument.presentation'
];

export class FileUtilities {

    static isPicture(mimetype: string): boolean {
        return mimetype && PICTURE_MIMETYPES.includes(mimetype) ;
    }

    static isAcceptedFileFormat(mimetype: string): boolean {
        return mimetype && ACCEPTED_MIMETYPES.includes(mimetype);
    }

}
