/*
 * QEOPS
 * Author EFWAY / F. Delaunay
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment as env } from '@src/environments/environment';
import { RestApiUtilities as RA } from '@src/app/_core/utils/rest-api.utilities';
import { BeUpdateResponse, ItemTypeEnum } from '@src/app/_core/interfaces/rest-api.interfaces';
import { Sort, Pagination, PaginatedList } from '@src/app/_core/interfaces/app.interfaces';
import { ErrorService } from '@src/app/_core/services/error.service';

export interface ResourceQualifier {
    // optional parent resource
    parentType?: ItemTypeEnum;
    parentId?: number;
    // resource
    itemType: ItemTypeEnum;
    itemId?: number|'me';
    // exceptional specific modifier at the end of the route
    specific?: string;
}

/*
 * Generic, canonical REST API requests:
 * Routes scheme :
 *    GET [/parents/{parentId}]/items   (+ filter/sort/pagination query params)
 *    GET /items/{itemId}
 *    GET [/parentType/parentId]/itemType/specific ?filter  :  used for check item existence
 *    POST [/parents/{parentId}]/items
 *    PUT /items/{itemId}
 *    PATCH /itemType/{itemId}[/specific]                   :  actually always used with a specific complement
 *    DELETE /items/{itemId}
 */

@Injectable({
    providedIn: 'root',
})
export class RestApiService {

    constructor(
        private http: HttpClient,
        private errorService: ErrorService
    ) {}

    /*
     * GET collection of items
     * GET [/parentType/parentId]/itemType [?filter/sort/pagination/additional query params]
     */
    searchItems<T>(resource: ResourceQualifier, filter?: any, sort?: Sort, pagination?: Pagination,
                   additionalParams?: {[key:string]: string}
                   ): Observable<PaginatedList> {
        const parent = resource.parentType ? `${resource.parentType}/${resource.parentId}/` : '';
        const url = `${env.beEndpoint}/${parent}${resource.itemType}`;

        let params = new HttpParams();
        params = RA.addFilter(params, filter);
        params = RA.addSort(params, sort);
        params = RA.addPagination(params, pagination);
        for (const param in (additionalParams||{}) ) {
            params = params.append(param, additionalParams[param]);
        }

        //return throwError(new HttpErrorResponse({status: 500, error: {message: 'OUPS'} })).pipe( // testing
        return this.http.get(url, {params, observe: 'response'}).pipe(
            map((response: HttpResponse<T[]>) => {
                return {
                    total: RA.getTotalCountInListResponse(response),
                    items: response.body as T[]
                };
            }),
            catchError(error => {
                this.errorService.handleError(error);
                return throwError(error);
            })
        );
    }

    /*
     * GET [/parentType/parentId]/itemType/specific ?filter
     */
    checkItemExists<T>(resource:ResourceQualifier, filter: any): Observable<T> {
        const parent = resource.parentType ? `${resource.parentType}/${resource.parentId}/` : '';
        const specific = resource.specific ? `/${resource.specific}` : '';
        const url = `${env.beEndpoint}/${parent}${resource.itemType}${specific}`;

        let params = new HttpParams();
        params = RA.addFilter(params, filter);

        return this.http.get<T>(url, {params: params} )
    }

    /*
     * Get item
     * GET /itemType/itemId
     */
    getItem<T>(resource: ResourceQualifier): Observable<T> {
        const url = `${env.beEndpoint}/${resource.itemType}/${resource.itemId}`;

        return this.http.get<T>(url).pipe(
            catchError(error => {
                this.errorService.handleError(error);
                return throwError(error);
            })
        );
    }

    /*
     * POST: parent is optional, specific is optional
     * POST [/parentType/parentId]/itemType[/specific]
     */
    postItem<T>(resource: ResourceQualifier, item: T): Observable<T> {
        const parent = resource.parentType ? `${resource.parentType}/${resource.parentId}/` : '';
        const specific = resource.specific ? `/${resource.specific}` : '';
        const url = `${env.beEndpoint}/${parent}${resource.itemType}${specific}`;

        return this.http.post<T>(url, item).pipe(
            catchError(error => {
                this.errorService.handleError(error);
                return throwError(error);
            })
        );
    }

    /*
     * PUT /itemType/itemId
     * PATCH /itemType/itemId[/specific]
     */
    putItem<T>(resource: ResourceQualifier, item: Partial<T>, verb: 'put'|'patch' = 'put'): Observable<number> {
        const specific = resource.specific ? `/${resource.specific}` : '';
        const url = `${env.beEndpoint}/${resource.itemType}/${resource.itemId}${specific}`;

        return this.http[verb]<BeUpdateResponse>(url, item).pipe(
            map( (response:BeUpdateResponse) => {
                return response.id;
            }),
            catchError(error => {
                this.errorService.handleError(error);
                return throwError(error);
            })
        );
    }

    /*
     * PATCH /itemType/itemId[/specific]
     */
    patchItem<T>(resource: ResourceQualifier, item: Partial<T>): Observable<number> {
        return this.putItem(resource, item, 'patch');
    }

    /*
     * DELETE /itemType/itemId
     */
    deleteItem(resource: ResourceQualifier): Observable<any> {
        const url = `${env.beEndpoint}/${resource.itemType}/${resource.itemId}`;

        return this.http.delete(url).pipe(
            catchError(error => {
                this.errorService.handleError(error);
                return throwError(error);
            })
        );
    }

}
