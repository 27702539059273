/*
 * Customizable spinner
 * Author EFWAY / F. Delaunay with css parts coming from SpinKit
 */


import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'fwy-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit{
    // Spinner comes with a transparent overlay that covers the whole surface of its container
    // Its container MUST have position:relative;

    @Input() show:boolean;
    @Input() type:'loader'|'uploader' = 'loader';
    @Input() size:number;// = 20; // default height or circle diameter or square side in px
    @Input() xPosition: 'left'|'right'|'center' = 'center'; // default horizontal position inside the container : left,right or center ;
    @Input() xPositionAdjust:string = '0'; // adjustment to left or right position ; ex:  xPosition=left xPositionAdjust='-5px' => left:-5px;
    @Input() yPosition: 'top'|'bottom'|'center' = 'center';// default vertical position inside the container : top, bottom or center ;
    @Input() yPositionAdjust:string = '0';
    @Input() theme:'dark'|'white' = 'white';

    width: number;
    height: number;
    top: string;
    bottom: string;
    left: string;
    right: string;

    ngOnInit(){
        //// Sizing the spinner :
        switch(this.type) {
            case 'uploader': {
                // "uploader" pattern is the "sk bouncer" (3 points)
                this.height = this.size || 6;
                this.width = this.height * (3+ 0.4 * 2); // 3 points spaced with 40% of their width
                break;
            }
            case 'loader':
            default: {
                // "loader" pattern is "sk circle"  (1 rotating circle)
                this.height = this.size || 20;
                this.width = this.height;
                break;
            }
        }
        //// Positionning the spinner :
        this.top = this.yPosition == 'center' ? 'calc(50% - '+this.height/2+'px)' : this.yPosition == 'top' ? this.yPositionAdjust : 'unset';
        this.bottom = this.yPosition == 'bottom' ? this.yPositionAdjust : 'unset';
        this.left = this.xPosition == 'center' ? 'calc(50% - '+this.width/2+'px)' : this.xPosition == 'left' ? this.xPositionAdjust : 'unset';
        this.right = this.xPosition == 'right' ? this.xPositionAdjust : 'unset'
    }


}
