import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService} from '@ngx-translate/core';

import{ DEFAULTS } from '@src/app/_core/defs/app.defs';
import{ SessionUserService } from '@src/app/_core/services/session-user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(private http: HttpClient,
                private translator: TranslateService,
                private userService: SessionUserService
                )
    {
        //==== Initialize global language and country
        // This is artificial because we currently only manage French language & localization
        const userLang  = DEFAULTS.user.lang;
        const userCountry = DEFAULTS.user.country;

        this.userService.setLocales(userLang, userCountry);
        this.translator.setDefaultLang(userLang);
        this.translator.use(userLang);
    }
}
