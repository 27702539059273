import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { LoginComponent } from '@src/app/login/login.component';
import { PageNotFoundComponent} from '@src/app/_shared/pagenotfound/page-not-found.component';

import { PATHS } from '@src/app/_core/defs/app.defs';

const PUBLIC_ROUTES: Routes = [
  { path: PATHS.login.relative, component: LoginComponent},
  { path: PATHS.lostpassword.relative, loadChildren: () => import('./lostpwd/lostpwd.module').then(m => m.LostpwdModule) },
  { path: '', redirectTo: PATHS.login.full, pathMatch: 'full'},
  { path: PATHS.private.relative, loadChildren: () => import('./private/private.module').then(m => m.PrivateModule)},
  { path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(PUBLIC_ROUTES,{ useHash: true, preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }) // { useHash: true, , onSameUrlNavigation: 'ignore' ou plutot 'refresh'}) ], //enableTracing: true,
  ],
  exports: [RouterModule]
})
export class PublicRoutingModule { }
