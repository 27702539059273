<mat-form-field
    class="fwy-spinner-container"
    style="width:100%;"
    [appearance]="appearance"
    [ngClass]="{
        'ng-dirty': filter.dirty,
        'ng-invalid': required && selectedItems.length == 0,
        'mat-form-field-invalid': required && selectedItems.length == 0,
        'app-field-highlight':
            required && requiredHighlight && selectedItems.length == 0,
        'ng-touched': filter.touched
    }"
>
    <fwy-spinner [show]="loadingList" [xPosition]="'right'"></fwy-spinner>
    <mat-label
        >{{ labelI18n | translate }} <span *ngIf="required">*</span></mat-label
    >
    <!-- le * est un sale workaround du pb Angular qui ne VEUT pas l'afficher  -->
    <mat-chip-list #chipList>
        <!-- [class.fwy-inputhighlighted]="selectedItems?.length" -->
        <!-- selected items --->
        <mat-chip
            *ngFor="let item of selectedItems"
            [selectable]="true"
            [removable]="true"
            (removed)="onRemoved(item)"
        >
            {{ displayItemInChipFn(item) }}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <!-- input to filter the autocompletion selection list --->
        <input
            #filterInput
            [formControl]="filter"
            placeholder="{{ placeholderI18n | translate }}"
            [matAutocomplete]="ac"
            [matChipInputFor]="chipList"
            [matChipInputAddOnBlur]="false"
            (matChipInputTokenEnd)="clearInput($event)"
            (focus)="onFocus()"
        />
        <mat-icon matSuffix style="color:#bbb">search</mat-icon>
    </mat-chip-list>

    <!-- the autocompletion selection list, filtered according input--->
    <mat-autocomplete
        #ac="matAutocomplete"
        [panelWidth]="'auto'"
        [displayWith]="displayItemInChipFn.bind(this)"
        (optionSelected)="onSelected($event)"
    >
        <mat-option
            *ngFor="let item of listFiltered$ | async"
            [value]="item"
            [innerHTML]="
                displayItemInListFn(item, filterInput.value) | safeHtml
            "
        >
        </mat-option>
    </mat-autocomplete>
    <wid-help-info
        *ngIf="!!infostickerI18n"
        [content]="infostickerI18n | translate"
    ></wid-help-info>
</mat-form-field>
