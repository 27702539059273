/*
 * QEOPS - (c) WIDIP Software 2020
 * Author EFWAY / F. Delaunay
 */

import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, of as observableOf, throwError, interval, Subscription } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import { environment as env} from  '@src/environments/environment';
import { PATHS } from '@src/app/_core/defs/app.defs';
import { BeAuthenticateRequest, BeAuthenticateResponse } from '@src/app/_core/interfaces/rest-api.interfaces';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private token : string = null;
    private refreshToken : string = null;

    private isLoggedBS:BehaviorSubject<boolean> = new BehaviorSubject(false);
    isLogged$ = this.isLoggedBS.asObservable();


    constructor(private router: Router,
                private http: HttpClient) { }

    /* ============================================================================================================== */
    /*  Public METHODS
    /* ============================================================================================================== */

    authenticate(login: string, password: string): Observable<any> {
        return this.beLogin(login, password).pipe(map( (response:BeAuthenticateResponse) => {
                if (response.token && response.refresh_token) {
                    this.setAuthenticationInfos(response.token, response.refresh_token, password);
                }
                return response;
            }));
    }

    refreshTheSessionToken(): Observable<string> {
        return this.beRefreshTheSessionToken(this.refreshToken).pipe(map( (response: BeAuthenticateResponse) => {
            env.debug && console.log('refreshToken -> got new token='+response.token);
            this.setToken(response.token);
            return response.token;
        }))
    }

    /*
    * Log out and clear session
    * Well, wait for the backend response to logout ... but don't care about returned error
    */
    logout(): Observable<any> {
        return this.beLogout().pipe(
            mergeMap( response => {
                this.clearAuthenticationInfos();
                sessionStorage.clear();
                this.router.navigate([PATHS.login.full]); // back to login page regardless response
                return observableOf(null);
            }),
            catchError(error => {
                this.clearAuthenticationInfos();
                sessionStorage.clear();
                this.router.navigate([PATHS.login.full]); // back to login page regardless response
                return throwError(error);
            }
        ));
    }

    /* ============================================================================================================== */
    /*  Misc. public METHODS */
    /* ============================================================================================================== */

    getToken():string {
        return this.token;
    }

    clearAuthenticationInfos(): void {
        this.isLoggedBS.next(false);
        this.unsetTokens();
    }

    // Check whether authenticated or not ; Handles the case of app reload and restores a token from local storage
    checkOrRestoreAuthenticationInfos(): boolean {
        if (this.isLoggedBS.getValue()) {
            return true;
        } else {
            // May be this is a reload -> try to get token
            this.token = localStorage.getItem('token') || null;
            this.refreshToken = localStorage.getItem('refreshToken') || null;
            if (this.token) { // && this.refreshToken ?
                // We have a token : although token may have expired we consider we are authenticated
                // and we accordingly get/set other auth. values
                this.isLoggedBS.next(true);
                return true;
            } else {
                return false;
            }
        }
    }

    /* ============================================================================================================== */
    /*  Misc. PRIVATE METHODS */
    /* ============================================================================================================== */
    private setAuthenticationInfos(token:string, refreshToken: string, password: string): void {
        this.isLoggedBS.next(true);
        this.setToken(token);
        this.setRefreshToken(refreshToken);
    }

    private setToken(token: string){
        this.token = token;
        localStorage.setItem('token', token);
    }
    private setRefreshToken(refreshToken: string){
        this.refreshToken = refreshToken;
        localStorage.setItem('refreshToken', refreshToken);
    }

    private unsetTokens(){
        this.token = null;
        this.refreshToken = null;
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
    }

    /* ============================================================================================================== */
    /*  PRIVATE REST API  methods */
    /* ============================================================================================================== */

    private beLogin(login: string, password: string): Observable<BeAuthenticateResponse> {
        const url = `${env.beEndpoint}/login_check`;
        const myReq: BeAuthenticateRequest = {username: login, password: password};

        return this.http.post<BeAuthenticateResponse>( url, myReq)
    }

    private beRefreshTheSessionToken(refreshToken: string): Observable<BeAuthenticateResponse> {
        const url = `${env.beEndpoint}/token/refresh`;
        // the f... gesdinet/jwt-refresh-token-bundle doesn't want json format but only that thing!!!:
        const myReq = `refresh_token=${refreshToken}`;
        const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'}),};
        return this.http.post<BeAuthenticateResponse>( url, myReq, httpOptions);
    }

    private beLogout(): Observable<any> {
        const url = `${env.beEndpoint}/logout`;
        return this.http.post<any>(url,null);
    }
}
