/*
 * QEOPS - WIDIP Software 2020
 * Author EFWAY / F. Delaunay
 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment as env } from '@src/environments/environment';
import { BeConfiguration } from '@src/app/_core/interfaces/rest-api.interfaces';


/*
 * Configuration service :
 *  Fetch some configuration parameters from the backend
 */
@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    configuration: BeConfiguration = null;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {}

    getConfiguration(): Observable<BeConfiguration> {
        const url = `${env.beEndpoint}/configuration`;
        return this.http.get<BeConfiguration>(url).pipe(
            tap((data:BeConfiguration) => {this.configuration = data}));
    }

}
