import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//// Project stuff
import { environment as env} from '@src/environments/environment';
import { SharedModule } from '@src/app/_shared/shared.module';
import { PublicRoutingModule } from 'src/app/public-routing.module';
import { AuthHeaderInterceptor } from '@src/app/_core/services/auth-header.interceptor';
import { AuthRefreshInterceptor } from '@src/app/_core/services/auth-refresh.interceptor';
import { SessionUserService} from '@src/app/_core/services/session-user.service';

import { AppComponent } from './app.component';
import { LoginComponent } from '@src/app/login/login.component';

//// Dates et localization
//import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter'; // all this mess for the mat-datepicker
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core'; // ... and this mess too

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
//import localeEn from '@angular/common/locales/en';
import { LOCALE_ID } from '@angular/core';
//registerLocaleData(localeEn);
registerLocaleData(localeFr);


// For a future user locales mgmt :
export function getUserLocales (sessionService:SessionUserService) {
    return sessionService.getLocales();
}

///// Translation
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http,'./assets/i18n/', `.json?${env.version}`);
}


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
    ],
    imports: [
        BrowserModule,
        SharedModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({loader: {provide: TranslateLoader, useFactory: (HttpLoaderFactory), deps: [HttpClient]}}),
        PublicRoutingModule,
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AuthRefreshInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true},
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        //{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]}, // for mat-datepicker
        //{provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}, // for mat-datepicker
        {provide: LOCALE_ID, useFactory: getUserLocales, deps: [SessionUserService]}, //for a language mgmt
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
