<div class="wid-contact-wrapper">
    <div class="wid-contact-info">
        <div class="wid-address">
            {{contact?.address}}
            <ng-container *ngIf="!!contact?.address && (!!contact?.zipcode || !!contact?.city)">,</ng-container>
            {{contact?.zipcode}}
            {{contact?.city}}
            <ng-container *ngIf="(!!contact?.address || !!contact?.zipcode || contact?.city) && !!contact?.country">,</ng-container>
            {{contact?.country}}
        </div>
        <div class="wid-other">
            <span *ngIf="contact?.email">{{'contact.field.email.labelShort'|translate}} : {{contact?.email}}</span>
            <span *ngIf="contact?.phone">{{'contact.field.phone.labelShort'|translate}} : {{contact?.phone}}</span>
        </div>
    </div>
    <div style="display: flex;">
        <button mat-icon-button *ngIf="canDo.update" (click)="openEditContactDialog(contact)"><mat-icon>edit</mat-icon></button>
        <button mat-icon-button *ngIf="canCreate" (click)="openEditContactDialog(null)"><mat-icon>add</mat-icon></button>
    </div>
</div>
