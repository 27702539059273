/*
 * QEOPS - (c) WIDIP Software
 * Author EFWAY - F. Delaunay
 */

import { DEFAULTS } from '@src/app/_core/defs/_defaults.defs';
import { Contact } from '@src/app/_core/model/contact.model';
import { Organization } from '@src/app/_core/model/organization.model';

export enum ProfileEnum {
    ADMIN= 'ROLE_ADMIN',
    ACCOUNTANT= 'ROLE_ACCOUNTANT',
    CLIENT_ADMIN = 'ROLE_CLIENT_ADMIN',
    CLIENT_USER = 'ROLE_CLIENT_USER',
    CLIENT_GUEST = 'ROLE_CLIENT_GUEST',
}

export class User  {
    public id?: number;
    public username?: string;
    public password?: string; // sent to server but not received from.
    //public roles?: ProfileEnum[];
    public profile?: ProfileEnum;
    public isActive?: boolean;
    public lastConnectedAt?: Date;
    public contact?: Contact;
    public organization?: Organization; // primary organization
    public organizations?: Organization[]; // all organizations the user belongs to, including the primary one
    // we keep language & country although QEOPS is currently only fr-fr
    public language?: string; // 'fr' | 'en'
    public countryCode?: string; // 'FR' ; used only for localization and different from contact.country which is the country name

    get isWidip(): boolean {
        return (this.profile == ProfileEnum.ADMIN) || (this.profile == ProfileEnum.ACCOUNTANT);
    }
    get isWidipAdmin(): boolean {
        return (this.profile == ProfileEnum.ADMIN);
    }

    get locales(): string {
        return `${this.language}-${this.countryCode}`;
    }
    get profileI18n(): string {
        return 'user.field.profile.value.'+this.profile;
    }

    get organizationsNames(): string {
        return this.organizations?.length > 0 ? this.organizations.map(o => o.name).join(', ') : '';
    }

    constructor(beUser?: any) {
        if (beUser) {
            Object.assign(this, beUser);
            this.lastConnectedAt = this.lastConnectedAt ? new Date(this.lastConnectedAt) : null;
            this.contact = beUser.contact ? new Contact(beUser.contact) : null;
            this.organization = beUser.organization ? new Organization(beUser.organization) : null;
            this.language = DEFAULTS.user.lang;
            this.countryCode = DEFAULTS.user.country;
        }
    }

    buildBeRequest(): any {
        //  full manual build of the request:
        const beUser: any = {};
        if (this.id || this.id === 0) beUser.id = this.id;
        beUser.username = this.username;
        if (this.password) beUser.password = this.password;
        beUser.profile =  this.profile;
        if (this.contact) {
            beUser.contact = this.contact.buildBeRequest();
        }
        beUser.organizationIds = this.organizations?.map(o => o.id);
        return beUser;
    }


}
