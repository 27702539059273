/*
 * QEOPS (c) Widip 2020
 * Author: EFWAY - F. Delaunay
 */

import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { environment as env } from '@src/environments/environment';
import { Contact } from '@src/app/_core/model/contact.model';
import { Organization } from '@src/app/_core/model/organization.model';
import { ActionEnum, ActionFeedback, MetaEditionFeedback } from '@src/app/_core/interfaces/app.interfaces';
import { OrganizationSelectorService } from '@src/app/_core/services/organization-selector.service';
import { ContactService } from '@src/app/_shared/contact/contact.service';
import { AlertService } from '@src/app/_core/services/alert.service';


/*
 * This component encapsulates the Contact Form in a Dialog modal, and handles creation or update actions
 */
@Component({
    selector: 'wid-contact-edit-dialog',
    templateUrl: './contact-edit-dialog.component.html'
})
export class ContactEditDialogComponent implements OnInit, OnDestroy {

    myForm: FormGroup;
    actionEnum = ActionEnum;

    organization: Organization;
    submitting: boolean = false;

    constructor(
        private orgSelectorService: OrganizationSelectorService,
        private alertService: AlertService,
        private contactService: ContactService,
        public dialogRef: MatDialogRef<ContactEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {contact: Contact, isCompany: boolean } // isCompany actually means "shall be a company"
    ) {}

    ngOnInit(): void {
        // we can't freeze selector because we can't know whether unfreeze it or not, because component if called from either a list either an edition
        //this.orgSelectorService.freezeSelector();
        // As a fallback alternative we get & check organization at init.
        this.organization = this.orgSelectorService.getCurrentOrganization(); //.selectedOrganizationBS.getValue();
        if (!this.organization) {
            this.alertService.error('common.app_error.noOrganization');
            this.onCancel();
        }
        this.myForm = new FormGroup({contactMeta: new FormControl(),});
    }

    ngOnDestroy(): void {
        //this.orgSelectorService.unfreezeSelector();
    }

    onContactChanged(event: MetaEditionFeedback<Contact>): void {
        this.myForm.get('contactMeta').setValue(event.item);
        this.myForm.get('contactMeta').markAsDirty();
        this.myForm.get('contactMeta').setErrors(event.invalid ? {sectionNok: true} : null);
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        const contact: Contact = this.myForm.get('contactMeta').value;

        this.submitting = true;
        if (this.data.contact) { // UPDATE context
            this.contactService.updateContact(contact).subscribe(
                (ok) => {
                    this.submitting = false;
                    this.dialogRef.close( <ActionFeedback<Contact>>{
                        action: ActionEnum.UPDATE,
                        item: contact
                    });
                    },
                (error) => {
                    this.submitting = false;
                }
            )
        } else {    // CREATION context
            this.contactService.createContact(this.organization.id, contact).subscribe(
                (newContact) => {
                    this.submitting = false;
                    this.dialogRef.close( <ActionFeedback<Contact>>{
                        action: ActionEnum.CREATE,
                        item: newContact
                    });
                },
                (error) => {
                    this.submitting = false;
                }
            );
        }
    }

}
