/*
 * (c) EFWAY 2018-2022
 * Author: EFWAY / F. Delaunay
 */

import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChange,
    OnDestroy,
    AfterViewInit
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { Observable, merge, fromEvent, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

import { environment as env } from "@src/environments/environment";
import { MetaEditionFeedback } from "@src/app/_core/interfaces/app.interfaces";
import { StringUtilities as SU } from "@src/app/_core/utils/string-utilities";

/**
 * Field for selection of a SINGLE item with an autocompletion list
 * Attempt to make it a bit generic, ASSUMING :
 *   items of the @Input()list are objects with an id
 *   autocompletion list is filtered by rule:  "item.searchProperty contains searchString"
 * TODO: gérer le cas où l'on ne peut pas fournir la liste complète de départ mais l'observable qui la fetche en la filtrant
 */
@Component({
    selector: "fwy-select-autocompletion-field",
    templateUrl: "./select-autocompletion-field.component.html"
})
export class SelectAutocompletionFieldComponent
    implements OnChanges, AfterViewInit, OnDestroy {
    @Input() list: any[] = []; // reference list of items that will be filtered with input on searchProperty ; items are intended to have an id
    @Input() loadingList: boolean = false;
    @Input() searchProperty: string; // name of the item property used to filter the list
    @Input() nullOption: boolean = false; // add the null value as option?
    @Input() selectedItem: any; // will be updated by this component
    @Input() excludedItems: any[] = []; // for  special case of mutual exclusion between 2 fields catered with the same list
    @Input() labelI18n: string;
    @Input() placeholderI18n: string;
    @Input() required: boolean = true;
    @Input() appearance: MatFormFieldAppearance = "outline"; // value for mat-field "appearance" // 'standard'
    @Input() optionsClass: string;
    @Input() displayItemFn: (item: any) => string = this.defaultDisplayItemFn;
    @Input() displayItemInListFn: (
        item: any,
        searchProperty?: string,
        searchString?: any
    ) => string = this.defaultDisplayItemInListFn;

    @Output() selectionChanged = new EventEmitter<MetaEditionFeedback<any[]>>();

    filter: FormControl = new FormControl(); // just the mat-input to enter the searchstring that filters the autocompletion list
    listFiltered$: Observable<any[]>;

    @ViewChild("filterInput") filterInput: ElementRef;

    stopSubscription$: Subject<boolean> = new Subject<boolean>();

    constructor() {}

    ngOnChanges(changes: { [propKey: string]: SimpleChange }): void {
        if (changes["selectedItem"]) {
        }
        if (
            changes["excludedItems"] &&
            !changes["excludedItems"].isFirstChange()
        ) {
            //&& !changes['excludedItems'].isFirstChange() ) {
            this.filter.setValue(this.filter.value); // trick to trigger a change
        }
    }

    //ngOnInit(): void {}

    ngAfterViewInit(): void {
        // filter selection list according input search text, and also excluding already selected items
        this.listFiltered$ = merge(
            fromEvent(this.filterInput.nativeElement, "click"),
            this.filter.valueChanges
        ).pipe(
            takeUntil(this.stopSubscription$),
            map(value =>
                typeof value === "string" ? value : value?.[this.searchProperty]
            ),
            map(searchString =>
                /* Pour Accent Insensitive:
                     * ceci marche mais:
                     * 1) est optimisable,
                     * 2) doit être mis en prod en même temps que la même chose pour highlightSearchText
                     * A noter pour le AI: tolocalelowercase ne marche pas, regexp avec "gi" ne marche pas non plus
                    if (searchString) {
                        const prepSearchString = SU.cleanAccentsLC(searchString.toLowerCase());
                        return this.list.filter(o =>
                            ( SU.cleanAccentsLC(o[this.searchProperty] as string).toLowerCase()).includes(prepSearchString)
                            && !this.excludedItems?.find(e => e.id == o.id)
                        )
                    } else {
                        return this.list.filter(o => !this.excludedItems?.find( e => e.id == o.id));
                    }
                     */
                searchString
                    ? this.list.filter(
                          o =>
                              (o[this.searchProperty] as string)
                                  .toLowerCase()
                                  .includes(searchString.toLowerCase()) &&
                              !this.excludedItems?.find(e => e.id == o.id)
                      )
                    : this.list.filter(
                          o => !this.excludedItems?.find(e => e.id == o.id)
                      )
            )
        );
    }

    ngOnDestroy(): void {
        this.stopSubscription$.next(true);
        this.stopSubscription$.unsubscribe();
    }

    defaultDisplayItemFn(item: any): string {
        //return item?.[this.searchProperty] || undefined;
        return item && typeof item != "string"
            ? item?.[this.searchProperty]
            : undefined; //item as string;
    }

    defaultDisplayItemInListFn(item: any, searchString: any): string {
        const label = this.defaultDisplayItemFn(item) || "";
        return SU.highlightSearchText(label, searchString);
    }

    /**
     * On veut utiliser le keyup.enter comme un raccourci clavier pour reseter le champ
     * Cà fonctionne ergonomiquement très bien, en particulier dans le contexte d'un filtre
     * Mais il faut juste éviter le cas où l'utilisateur a utilisé le clavier (fleches puis enter) pour sélection
     * un élément dans la liste : on le discrimine  si la valeur retournée par l'event est égale à displayItemFn(selectedItem)
     */
    onKeyEnter(event): void {
        const value = event.target.value;
        if (!(value && value == this.displayItemFn(this.selectedItem))) {
            this.filterInput.nativeElement.value = null;
            //this.selectedItem = null;
            this.selectionChanged.emit({ item: null, invalid: this.required });
        }
    }

    onSelected(event: MatAutocompleteSelectedEvent): void {
        //this.selectedItem = event?.option.value;
        this.selectionChanged.emit({
            item: event?.option.value || null, //this.selectedItem || null,
            invalid: this.required && !event?.option.value //!this.selectedItem
        });
    }
}
