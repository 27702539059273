/*
 * Structure that defines the private menu
 *
 */
import { PATHS } from '@src/app/_core/defs/_paths.defs';
import { Path } from '@src/app/_core/interfaces/app.interfaces';

 //// Menu
// 'access' finally are NOT are handled here but in template because they depend upon async. info that are easiest to manage from the template (todo properly manage  access and async info from here)
export const MENU: {[key:string]: {access?:boolean, path:Path, label: string, icon: string}} = {
    home: {
        //access: null,
        path: PATHS.home,
        label: 'private.menu.home',
        icon: 'home'},
    processing: {
        path: PATHS.processing,
        label: 'private.menu.processing',
        icon: 'fact_check',}, // 0.9: miscellaneous_services // essais: graphic_eq, settings_applications, miscellaneous_services
    pia: {
        path: PATHS.pia,
        label: 'private.menu.pia',
        icon: 'leaderboard',}, // 0.9: verified_user
    provider: {
        path: PATHS.subcontracting,
        label: 'private.menu.provider',
        icon: 'plumbing',}, // 0.9: local_mall //essais: recent_actors, local_mall, supervised_user_circle, contact_page
    incident: {
        path: PATHS.incident,
        label: 'private.menu.incident',
        icon: 'report_problem',}, // 0.9: report_problem
    personrequest: {
        path: PATHS.personrequest,
        label: 'private.menu.personRequest',
        icon: 'face',},
    guide: {
        path: PATHS.guide_list, // use list because there is no way to define a default (because common to guide & shared_guide)
        label: 'private.menu.guide',
        icon: 'import_contacts',}, // 0.9: library_books
    shared_guide: {
        path: PATHS.shared_guide_list, // use list because there is no way to define a default (because common to guide & shared_guide)
        label: 'private.menu.sharedGuide',
        icon: 'menu_book',}, // 0.9: library_books
    admin: {
        path: PATHS.admin_home,
        label: 'private.menu.admin',
        icon: 'construction',}, // build
    admin_organization: {
        path: PATHS.admin_organization,
        label: 'private.menu.adminSub.organization',
        icon: null}, //'business'
    admin_user: {
        path: PATHS.admin_user,
        label: 'private.menu.adminSub.user',
        icon: null}, // 'group'
    client_admin_user: {
        path: PATHS.client_admin_user,
        label: 'private.menu.clientadmin.user',
        icon: 'group'}, // 'group'
    admin_processing_tpl: {
        path: PATHS.admin_processing_tpl_list, //PATHS.admin_processing_tpl,
        label: 'private.menu.adminSub.processingTpl',
        icon: null},
    admin_structure: {
        path: PATHS.admin_structure,
        label: 'private.menu.adminSub.structure',
        icon: null}, //'content_copy'
}

