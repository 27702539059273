/*
 * STRING utilities
 * Author EFWAY / F. DELAUNAY
 */

export class StringUtilities {
    // Found nothing on-the-shelve (sic!) to clean accents from a string in order to make an accent-insensitive comparison
    // This method cleans accents from a LOWCASE string
    // todo rework en s'inspirant de slugify()
    static cleanAccentsLC(text: string): string {
        // const from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿž";
        // const to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyz";
        const from = "áäâàçéëèêîïñöôõüùû";
        const to = "aaaaceeeeiinooouuu";

        let textLC = (text || "").toLowerCase();
        for (let i = 0, l = from.length; i < l; i++) {
            textLC = textLC?.replace(
                new RegExp(from.charAt(i), "g"),
                to.charAt(i)
            );
        }
        return textLC;
    }

    // Code from the PIA front-end by the CNIL
    static slugify(text: string): string {
        const a =
            "àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;";
        const b =
            "aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------";
        const myRegexp = new RegExp(a.split("").join("|"), "g");

        return text
            .toString()
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace spaces with -
            .replace(myRegexp, c => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, "-") // Replace & with 'and'-> no, with '-'
            .replace(/[^\w\-]+/g, "") // Remove all non-word characters
            .replace(/\-\-+/g, "-") // Replace multiple - with single -
            .replace(/^-+/, "") // Trim - from start of text
            .replace(/-+$/, ""); // Trim - from end of text
    }

    // aB -> a_b
    static camelToSnakeCase(text: string): string {
        return text.replace(
            /([a-z])([A-Z])/g,
            (str, min, maj) => `${min}_${maj.toLowerCase()}`
        );
    }

    // a_b -> aB
    static snakeToCamelCase(text: string): string {
        return text.replace(
            /([a-z])_([a-z])/g,
            (str, min1, min2) => `${min1}${min2.toUpperCase()}`
        );
    }

    /*
     * truncates at the last entire word before maxChar displayable
     * algo usine-à-gaz mais je ne parviens pas à faire marcher une regexp pour virer le dernier bout de mot après troncature
     */
    static truncateText(
        text: string,
        maxChar: number,
        moreString: string = ""
    ): string {
        if (text && text.length > maxChar) {
            let smartMaxChar = 1;
            for (const stopChar of [" ", ",", ".", ";"]) {
                let index = text.lastIndexOf(stopChar, maxChar - 1);
                // best fit :
                if (index + 1 == maxChar) {
                    smartMaxChar = index + 1;
                    break;
                } else {
                    smartMaxChar = Math.max(smartMaxChar, index + 1);
                }
            }
            return text.substring(0, smartMaxChar).concat(moreString);
        } else {
            return text;
        }
    }

    /*
     * Return the string trimmed and, if max is provided, stripped to maxlength, or ''
     * Useful before submitting a form field
     */
    static trimAndStrip(value: string, max?: number): string {
        return (value || "").trim().substring(0, max || value?.length || 0);
    }

    /*
     * Same but without trimming the value and returning a null value rather than ''
     * Useful before submitting a search field : no trim to avoid bizarre UX
     */
    static strip(value: string, max?: number): string {
        return (value || "").substring(0, max || value?.length || 0) || null;
    }

    /**
     * Highlight search string in text, using the fwy-text-highlighted css class
     */
    static highlightSearchText(text: string, searchString: any): string {
        if (typeof searchString === "string" && searchString) {
            // RE is used for its case-insensitive mode "i" and its global mode "g"
            // Applying spaces replacement is a workaround for a very stupid Angular bug (in DomSanitizer I guess or may be in mat-option?)
            // ... : else, spaces just before or just after our <span> will be eaten because our <span> is included in DOM with new line after/before!
            const reSpace = new RegExp(`(\\s)`, "g");
            const re = new RegExp(`(${searchString})`, "gi");

            return (
                "<span>" +
                (text || "")
                    .replace(reSpace, "&#32;")
                    .replace(
                        re,
                        '<span class="fwy-text-highlighted">' + "$1" + "</span>"
                    ) +
                "</span>"
            );
        } else {
            return text || "";
        }
    }
}
