import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularMaterialModule } from "@src/app/_shared/angular-material.module";
//import { TagInputModule } from 'ngx-chips';
//import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

//// Project stuff
import { environment as env } from "@src/environments/environment";

// Components
import { SpinnerComponent } from "@src/app/_shared/spinner/spinner.component";
import { PageNotFoundComponent } from "@src/app/_shared/pagenotfound/page-not-found.component";
import { PageForbiddenComponent } from "@src/app/_shared/pageforbidden/page-forbidden.component";
import { FieldErrorComponent } from "@src/app/_shared/field-error/field-error.component";

import { InfoDialogComponent } from "@src/app/_shared/modals/info-dialog.component";
import { ConfirmDialogComponent } from "@src/app/_shared/modals/confirm-dialog.component";
import { HelpInfoComponent } from "@src/app/_shared/help-info/help-info.component";
import { ModalAsTooltipComponent } from "@src/app/_shared/modals/modal-as-tooltip.component";
import { ChipAutocompletionFieldComponent } from "@src/app/_shared/chipautocompletionfield/chip-autocompletion-field.component";
import { SelectAutocompletionFieldComponent } from "@src/app/_shared/selectautocompletionfield/select-autocompletion-field.component";
import { LocationComponent } from "@src/app/_shared/location/location.component";
import { LocationEditDialogComponent } from "@src/app/_shared/location/edit/location-edit-dialog.component";
import { LocationDeleteDialogComponent } from "@src/app/_shared/location/delete/location-delete-dialog.component";

import { ContactComponent } from "@src/app/_shared/contact/contact.component";
import { ContactFormComponent } from "@src/app/_shared/contact/_form/contact-form.component";
import { ContactEditDialogComponent } from "@src/app/_shared/contact/edit/contact-edit-dialog.component";

import { DocumentComponent } from "@src/app/_shared/document/document.component";
import { DocumentFormComponent } from "@src/app/_shared/document/docform/doc-form.component";

import { Nl2brPipe, WidDatePipe, SafeHtmlPipe } from "./pipes";
import { InputTrimDirective } from "@src/app/_shared/trim.directive";

//// Translation
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
// For translation - the CNIL loader is replaced by the native ngx-translate loader
//import { PiaTranslateLoader } from './pia-translate-loader';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(
        http,
        "./assets/i18n/",
        `.json?${env.version}`
    );
}

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        //PdfJsViewerModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        SpinnerComponent,
        PageNotFoundComponent,
        PageForbiddenComponent,
        FieldErrorComponent,
        InfoDialogComponent,
        ConfirmDialogComponent,
        HelpInfoComponent,
        ModalAsTooltipComponent,
        ChipAutocompletionFieldComponent,
        SelectAutocompletionFieldComponent,

        // KnowledgeBaseComponent,
        // KnowledgeBaseItemComponent,

        LocationComponent,
        LocationEditDialogComponent,
        LocationDeleteDialogComponent,

        ContactComponent,
        ContactFormComponent,
        ContactEditDialogComponent,

        DocumentComponent,
        DocumentFormComponent,

        //SafeHtmlPipe,
        //FilterForUser,
        Nl2brPipe,
        WidDatePipe,
        SafeHtmlPipe,
        InputTrimDirective
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        TranslateModule,
        //PdfJsViewerModule,
        //TagInputModule,

        SpinnerComponent,
        PageNotFoundComponent,
        PageForbiddenComponent,
        FieldErrorComponent,
        InfoDialogComponent,
        ConfirmDialogComponent,
        ModalAsTooltipComponent,
        HelpInfoComponent,
        ChipAutocompletionFieldComponent,
        SelectAutocompletionFieldComponent,

        //KnowledgeBaseComponent,
        //KnowledgeBaseItemComponent,

        LocationComponent,
        LocationEditDialogComponent,
        LocationDeleteDialogComponent,

        ContactComponent,
        ContactFormComponent,
        ContactEditDialogComponent,

        DocumentComponent,
        DocumentFormComponent,

        //SafeHtmlPipe,
        //FilterForUser,
        Nl2brPipe,
        WidDatePipe,
        SafeHtmlPipe,
        InputTrimDirective
    ],
    providers: []
})
export class SharedModule {}
