/*
 * QEOPS - (c) WIDIP Software
 * Author EFWAY F. Delaunay
 *
 */

import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { environment as env} from '@src/environments/environment';
import { CanDo, ActionEnum, ActionFeedback } from '@src/app/_core/interfaces/app.interfaces';
import { ACL } from '@src/app/_core/defs/_acl.defs';
import { DialogUtilities as DG } from '@src/app/_core/utils/dialog-utilities';
import { AclService } from '@src/app/_core/services/acl.service';
import { ViewportService} from '@src/app/_core/services/viewport.service';
import { Contact } from '@src/app/_core/model/contact.model';

import { ContactEditDialogComponent} from '@src/app/_shared/contact/edit/contact-edit-dialog.component';

@Component({
    selector: 'wid-contact',
    templateUrl: 'contact.component.html'
})
export class ContactComponent implements OnInit {
    @Input() contact: Contact;
    @Input() isCompany: boolean = false; // isCompany = true actually means "shall be a company"
    @Output() actionFeedback = new EventEmitter<ActionFeedback<Contact>>();

    action:ActionEnum;

    canCreate: boolean; // access to the CREATE button
    canDo: CanDo;

    constructor(
        public dialog: MatDialog,
        private aclService: AclService,
        private viewportService: ViewportService,
    ) {}

    ngOnInit() {}

    ngOnChanges(changes: {[propKey: string]: SimpleChange}): void {
        if ( changes['contact'] ) { //&& !changes['contact'].isFirstChange()
            this.canDo = this.initCanDo(this.contact);
            this.canCreate = this.aclService.canAccess(ACL.contact_manage); //!this.contact &&
        }
    }

    openEditContactDialog(contact: Contact): void {
        const dialogRef = this.dialog.open(ContactEditDialogComponent,
            { ...DG.modalFormConfig(this.viewportService.viewport), data: {contact: contact, isCompany: this.isCompany} });

        // Get result back and handle it
        dialogRef.afterClosed().subscribe(
            (feedback: ActionFeedback<Contact>) => {
                if(!!feedback) { // we have a result (means: no cancel of the edition)
                    this.actionFeedback.emit(feedback);
                }
            });
    }

    private initCanDo(contact: Contact): CanDo {
        return {
            update: contact && this.aclService.canAccess(ACL.contact_edit), // && this.aclService.inScope(contact.organizationId),
            delete: contact && !contact.userId && this.aclService.canAccess(ACL.contact_manage), // && this.aclService.inScope(contact.organizationId)
        }
    }

}
