/*
 * Defines page titles (i18 code) to be displayed in the header
 * Routes that are not present here just don't have a page title
 */

import { PATHS } from '@src/app/_core/defs/_paths.defs';

export const PAGE_TITLES:{[url:string]: string} = {
    [PATHS.forbidden.full]: 'common.pageError.forbidden.title',
    [PATHS.home.full]: 'home.title',
    [PATHS.pia.full]: 'pia.title',
    [PATHS.processing.full]: 'processing.title',
    [PATHS.processing_list.full]: 'processing.title',
    [PATHS.subcontracting.full]: 'provider.title',
    [PATHS.subcontracting_provider_list.full]: 'provider.list.title',
    [PATHS.incident.full]: 'incident.title',
    [PATHS.incident_list.full]: 'incident.title',
    [PATHS.personrequest.full]: 'personRequest.title',
    [PATHS.personrequest_list.full]: 'personRequest.title',
    [PATHS.guide.full]: 'guide.title',
    [PATHS.guide_list.full]: 'guide.title',
    [PATHS.shared_guide.full]: 'guide.titleShared',
    [PATHS.shared_guide_list.full]: 'guide.titleShared',
    [PATHS.admin_organization.full]: 'organization.title',
    [PATHS.admin_user.full]: 'user.title',
    [PATHS.admin_structure.full]: 'structure.title',
    [PATHS.admin_processing_tpl.full]: 'processingTpl.title',
    [PATHS.admin_processing_tpl_list.full]: 'processingTpl.title',
};
