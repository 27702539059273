<!--<div class="wid-location">
    <div style="margin-top:-8px;text-align: right;">
    <button mat-icon-button (click)="shrinked = !shrinked" >
        <mat-icon>{{shrinked ? 'expand_more' : 'expand_less'}}</mat-icon>
    </button>
   </div>
    <div class="wid-shrinkable-h" [class.wid-shrinked]="shrinked">
-->
<div>
        <div *ngFor="let locUI of locationUIList" class="wid-location-item-wrapper">
            <div>
                {{locUI.location.name}}
                <!--
                <div class="wid-address">
                    {{locUI.location.address}}
                    <ng-container *ngIf="!!locUI.location.address && (!!locUI.location.zipcode || !!locUI.location.city)">,</ng-container>
                    {{locUI.location.zipcode}} {{locUI.location.city}}
                </div>
                -->
            </div>
            <div style="display: flex;">
                <button mat-icon-button *ngIf="locUI.canDo.update"
                        (click)="openEditLocationDialog(locUI.location, actionEnum.UPDATE)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button *ngIf="locUI.canDo.delete"
                        (click)="openDeleteLocationDialog(locUI.location)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>

        <button mat-icon-button *ngIf="canCreate" (click)="openEditLocationDialog(null, actionEnum.CREATE)"
                matTooltip="{{'location.list.action.create.tooltip'|translate}}">
            <mat-icon>add</mat-icon>
        </button>
   <!--
   </div>
   -->
</div>

