<div class="wid-document">
    <div class="fwy-spinner-container" style="line-height: 1;"
         matTooltip="{{'common.document.action.download'|translate}}"
         (click)="downloadAndSaveFile()" >
        <fwy-spinner [show]="loading || loadingImg"></fwy-spinner>
        <img  *ngIf="isPicture && fileUrl" class="materialboxed" [src]="fileUrl" alt="{{document?.name}}">
        <mat-icon *ngIf="!isPicture || (isPicture && loadingImg)">insert_drive_file</mat-icon>
    </div>
    <div style="display: flex; flex-direction:row; align-items: center;">
        <span class="filename">{{document?.name}}</span>
        <mat-icon *ngIf="isDeletable"
                  class="wid-action wid-doc-delete"
                  matTooltip="{{'common.document.action.delete'|translate}}"
                  (click)="confirmDeleteDocument()">close</mat-icon>
    </div>
</div>
