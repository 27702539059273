
<h2 mat-dialog-title *ngIf="data?.title">{{data.title | translate}}</h2>
<!-- <span class="wid-action wid-close" (click)="onClose()"><mat-icon>close</mat-icon></span> -->
<div mat-dialog-content >
    <p style="text-align: center;" [innerHTML]="data.content| translate"></p>
</div>

<!--<div mat-dialog-actions> -->
<div class="wid-form-actions dialog">
    <button mat-raised-button color="accent" type="submit"
            [innerHTML]="(data?.confirm || 'common.action.continue.label') | translate"
            (click)="onConfirm()">
    </button>
    <button mat-button
            [innerHTML]="(data?.cancel || 'common.action.cancel.label') | translate"
            (click)="onCancel()">
    </button>
</div>
<div class="bottomText" *ngIf="data?.bottomContent" [innerHTML]="data.bottomContent | translate"></div>
