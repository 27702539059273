/*
 * Author EFWAY / F. Delaunay
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment as env} from '@src/environments/environment';
import { Organization } from '@src/app/_core/model/organization.model';
import { OrganizationService } from '@src/app/organization/organization.service';
import { SessionUserService } from '@src/app/_core/services/session-user.service';
import { User } from '@src/app/_core/model/user.model';

/*
 * The organization selector component allows a widip user or a multi-spaces user to select an organization in order to work on its items
 * This service offers methods for this component (to get the org. list)
 *  and also to all other :
 *   get current organization : the selected one for a widip user, it's own organization for a client user
 *   freeze selector in situation where we don't want to change organization, for instance while editing any org. item (pia, processing, etc...)
 *   unfreeze it when coming back from this situation
 *
 */
@Injectable({
    providedIn: 'root'
})
export class OrganizationSelectorService {

    organizationListBS: BehaviorSubject<Organization[]> = new BehaviorSubject([]);
    selectedOrganizationBS: BehaviorSubject<Organization> = new BehaviorSubject(null);
    selectorFreezedBS: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private organisationService: OrganizationService,
        private sessionService: SessionUserService
    ) {
        const selectorFreezedPersisted = sessionStorage.getItem('OrganizationSelectorService_selectorFreezed');
        if (selectorFreezedPersisted == '1') {
            this.freezeSelector()
        } else {
            this.unfreezeSelector();
        }
    }

    /*
     * Current organization is the selected one if user has widip profile or a multi-organizations access,
     * else it is the user's one
     */
    getCurrentOrganization(): Organization {
        const user: User = this.sessionService.user;
        let organization: Organization;
        if (user?.isWidip || user.organizations?.length > 1) {
            organization = this.selectedOrganizationBS.getValue();
        } else {
            organization = this.sessionService.user?.organization;
        }
        return organization
    }

    freezeSelector(): void {
        this.selectorFreezedBS.next(true);
        sessionStorage.setItem('OrganizationSelectorService_selectorFreezed', '1');
    }
    unfreezeSelector(): void {
        this.selectorFreezedBS.next(false);
        sessionStorage.setItem('OrganizationSelectorService_selectorFreezed', '0');
    }

    initOrRefreshOrganizationList(defaultOrgId?:number): Observable<void> {
        const user: User = this.sessionService.user;
        if (user.isWidip) {
            //== user has a widip profile
            return this.organisationService.getOrganizationList({isQeops: false}, {column: 'name', direction:'asc'}).pipe(
                map((organizationsPL) => {
                    this.setSelector(organizationsPL.items,defaultOrgId);
                }))
        } else if (user.organizations?.length > 1) {
            //== user  has access to several organizations
            return observableOf(this.setSelector(user.organizations,defaultOrgId));
        } else  {
            //== simple user - should not occur here
            return observableOf(null);
        }

    }

    private setSelector(organizationList:Organization[], defaultOrgId?:number): void {
        // update the  local list
        this.organizationListBS.next(organizationList);

        // Refresh the selected organization if it was set, or provided as call arg (which means we are returning from a page refresh)
        if (defaultOrgId || this.selectedOrganizationBS.getValue()) {
            // check that the selected org has not been deleted
            const presetOrgId = defaultOrgId ? defaultOrgId : this.selectedOrganizationBS.getValue().id;
            const organization = this.organizationListBS.getValue().find( org => org.id == presetOrgId);
            if (organization) { // organization is in the list, select it
                this.selectedOrganizationBS.next(organization)
            } else { // organization has  been deleted -> reset selector ; todo weakness here ? what if non-widip, multi org user and org non found ? can this occur ?
                this.selectedOrganizationBS.next(null);
            }
        }
    }

}
