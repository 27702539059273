/*
 * Interceptor that refreshes token
 * Author EFWAY - F. Delaunay
 */

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { environment as env } from '@src/environments/environment';
import { SESSION_EXPIRED_MSG } from '@src/app/_core/interfaces/rest-api.interfaces';
import { AuthenticationService } from '@src/app/_core/services/authentication.service';


@Injectable()
export class AuthRefreshInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthenticationService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status == 401 && (error.error?.message||'').toUpperCase() == SESSION_EXPIRED_MSG) {
                    // token expired -> refresh it then resend the request
                    return this.authService.refreshTheSessionToken().pipe(
                        mergeMap((newToken: string) => {
                            //return next.handle(req);
                            const clonedReq = req.clone({headers: req.headers.set('Authorization', 'Bearer ' + newToken) });
                            return next.handle(clonedReq);
                        }),
                        catchError(error => {
                            return throwError(error);
                        })
                    );
                } else {
                    return throwError(error);
                }
        }))
    }
}
