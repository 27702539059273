/*
 * Displays  end-user notifications ("alerts") using Angular Material "SnackBar"" component
 * Author EFWAY / F. Delaunay
 */

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

const SNACK_CONFIG:any = {
    duration: 4000,
    horizontalPosition: "left",
    verticalPosition: "top"
};

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor(
        private translate: TranslateService,
        private snackBar: MatSnackBar
    ) { }

    success(message: string) {
        this.translate.get(message).subscribe((res: string) => {message = res  });
        //M.toast({html: message, displayLength:4000, classes:'toast--success'});
        this.snackBar.open(message, null, {...SNACK_CONFIG, panelClass:['toast','toast--success']});
    }

    error(message: string) {
        this.translate.get(message).subscribe((res: string) => {message = res  });
        //M.toast({html: message, displayLength:4000, classes:'toast--alert'});
        this.snackBar.open(message, null, {...SNACK_CONFIG, panelClass:['toast','toast--alert']});
    }

    warning(message: string) {
        this.translate.get(message).subscribe((res: string) => {message = res  });
        //M.toast({html: message, displayLength:4000, classes:'toast--warning'});
        this.snackBar.open(message, null, {...SNACK_CONFIG, panelClass:['toast','toast--warning']});
    }

    info(message: string) {
        this.translate.get(message).subscribe((res: string) => {message = res  });
        //M.toast({html: message, displayLength:4000, classes:'toast--info'});
        this.snackBar.open(message, null, {...SNACK_CONFIG, panelClass:['toast','toast--info']});
    }

}
