/*
 * Common, internal interfaces (other than rest api interfaces)
 * Author EFWAY / F. delaunay
 */

import { ProfileEnum } from '@src/app/_core/model/user.model';
import { FieldError } from '@src/app/_shared/field-error/field-error.interface';

//================ ACL, navigation =====================//

export interface AccessControl {
    profile: ProfileEnum,
}

// deeplink of the app, with access controls
export interface Path {
    full: string,
    relative:string,
    acl: AccessControl[], // null or empty means "no restriction"
}

//=============== Time =============== //
export interface DateRange {
    start: Date,
    end: Date
}

//=============== Actions =============== //
// standard actions on an item:
export enum ActionEnum {
    CREATE,
    UPDATE,
    DELETE,

    // following actions are not systematic although pretty common, assuming their end-user labels may vary :
    DEACTIVATE, // <=> "close", "lock", "archive", ...
    ACTIVATE,   // <=> "reopen", "unlock", "unarchive", ...
}

// interface defining detailed ACL on a given item instance in a collection;
// "create" is not present because not applicable to an item
export interface CanDo {
    update: boolean;
    delete: boolean;
    // Same remark than for ActionEnum:
    deactivate?: boolean;
    activate?: boolean;
}

//
export interface ActionFeedback<T=any> {
    action: ActionEnum|any;
    item: T;
}

export interface MetaEditionFeedback<T> { // meta field edition feedback from the handler component to the parent component
    invalid: boolean;
    item: T;
}

//=============== LISTS: Filter, sort, pagination =============== //

export interface Pagination {
    currentPage: number; // start from 1
    maxPerPage: number;
}

export interface Sort {
    column: string,
    direction: any; //'asc'|'desc'|'' // same as MatSort to avoid type issue
}

export interface FSP {
    filter?:{default: any, persisted:boolean},
    sort?: {default: Sort, persisted:boolean},
    pagination?: {default: Pagination, persisted:boolean}
    SSKey?: string // session storage component key
}

// interface used by  the high-level communication layer (in service) to return a list of items to the business layer (in component)
export interface PaginatedList {
    total: number;  // grand total of items that fullfill the filter criteria ; this is not the item count of response.items, which may be different if paginating
    items: any[];   // list of items for the current page
}

//============= Form control asserts ==========================//
// a structure that gathers assertions for the fields of a form

export interface fcAsserts {
    [formfield:string]: {
        assert?: any;   // for instance: assert: {minlength: 8, maxlength: 100} or {pattern: /^[^ ]{8,100}$/}
        errors?: FieldError[];
    }
}
