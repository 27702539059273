/*
 * QEOPS -(c) WIDIP 2021
 * Author EFWAY - F. Delaunay
 */


export class Location  {
    public id?: number;
    public name?: string;
    public address?: string;
    public zipcode?: string;
    public city?: string;
    public country?: string;
    public organizationId?: number;

    constructor(beLocation?: any) {
        if (beLocation) {
            Object.assign(this, beLocation);
        }
    }

    buildBeRequest(): any {
        const beLocation: any = {};
        return Object.assign(beLocation, this);
    }
}
